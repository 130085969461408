import PropTypes from 'prop-types';
import React from 'react';

/**
 * Input, textareas and selects that can be used in forms.
 * @param elementType
 * @param id
 * @param name
 * @param value
 * @param changed
 * @param errors
 * @param placeholder
 * @param selectOptions
 * @param label
 * @param disabled
 * @param inputType
 * @param icon
 * @returns {*}
 * @constructor
 */

export default function FormElement({
  elementType,
  id,
  name,
  value,
  changed,
  errors,
  placeholder,
  selectOptions,
  label,
  disabled,
  inputType = 'text',
  icon = null,
  className,
  onFocus,
  onBlur,
}) {
  let element = null;

  switch (elementType) {
    case ('input'):
      element = (
        <input
          id={id}
          name={name || id}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={changed}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    case ('textarea'):
      element = (
        <textarea
          id={id}
          name={name || id}
          placeholder={placeholder}
          value={value}
          onChange={changed}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
      break;
    case ('select'):
      element = (
        <select
          id={id}
          name={name || id}
          onChange={changed}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          value={value}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>{option.text}</option>
          ))}
        </select>
      );
      break;
    default:
      element = (
        <input
          id={id}
          name={name || id}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={changed}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      );
  }

  return (
    <div
      className={`
        form-group
        ${(icon === null) ? '' : 'has-icon'}
        ${errors.length ? 'error' : ''}
        ${className}
      `}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <div className="input-wrapper">
        {element}
        {icon}
      </div>
      {errors && errors.map((error) => <p key={error} className="error">{error}</p>)}
    </div>
  );
}

FormElement.propTypes = {
  /** Id for the form element. */
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  /** Placeholder of the form element. */
  placeholder: PropTypes.string.isRequired,
  /** Label of the form element. */
  label: PropTypes.string,
  /** Type of the form element
  * Supports: "input", "textarea", "select". */
  elementType: PropTypes.string.isRequired,
  /** The type attribute of an input element */
  inputType: PropTypes.string,
  /** The value of the input element */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** expects onChange function */
  changed: PropTypes.func.isRequired,
  /** Disabled input */
  disabled: PropTypes.bool,
  /** Error message */
  errors: PropTypes.arrayOf(PropTypes.string),
  /** The select selectOptions */
  selectOptions: PropTypes.arrayOf(PropTypes.object),
  /** The svg icon component */
  icon: PropTypes.element,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

FormElement.defaultProps = {
  className: '',
  errors: [],
  id: '',
  name: '',
  label: '',
  inputType: 'text',
  selectOptions: [],
  icon: null,
  disabled: false,
  onFocus: null,
  onBlur: null,
};
