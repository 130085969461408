import Resource from './Resource';

export default class Subscription extends Resource {
  static collectionKey = 'subscriptions';

  constructor(
    id,
    status,
    plan,
    price,
    trialEndsAt,
    createdAt,
  ) {
    super(id);
    this._status = status;
    this._plan = plan;
    this._price = price;
    this._trialEndsAt = trialEndsAt;
    this._createdAt = createdAt;
  }

  get status() {
    return this._status;
  }

  get plan() {
    return this._plan;
  }

  get price() {
    return this._price;
  }

  get trialEndsAt() {
    return this._trialEndsAt;
  }

  get createdAt() {
    return this._createdAt;
  }

  static fromAPI(object) {
    return new Subscription(
      object.id,
      object.status,
      object.plan,
      object.price,
      object.trial_ends_at,
      object.created_at,
    );
  }
}
