/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

import CountryContext from '../../react-web-ui/contexts/CountryContext';

export default function CustomPhoneInput({
  noLabel,
  values,
  setValues,
  errors,
  lang,
}) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);

  return (
    <div className="phone-wrapper">
      <div
        className={`
          form-group
          ${errors.length ? 'error' : ''}
        `}
      >
        {!noLabel && (
        <label>
          {t('forms:phone')}
        </label>
        )}
        <div className="custom-phone-input">
          <PhoneInput
            country={lang || countryContext.country.code.toLowerCase()}
            value={values.phone}
            onChange={(phone) => {
              setValues({
                ...values,
                phone: `+${phone}`,
              });
            }}
            preferredCountries={['bg', 'pl', 'hr', 'it']}
            placeholder={t('forms:phone')}
            enableSearch
          />
        </div>
        {errors && errors.map((error) => <p key={error} className="error">{error}</p>)}
      </div>
    </div>
  );
}

CustomPhoneInput.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.array]),
  values: PropTypes.oneOfType([PropTypes.object]),
  setValues: PropTypes.func,
  noLabel: PropTypes.bool,
  lang: PropTypes.string,
};

CustomPhoneInput.defaultProps = {
  errors: [],
  values: {},
  setValues: null,
  noLabel: false,
  lang: null,
};
