@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.date-picker {
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 180px;
  margin-bottom: 15px;
  padding-right: 25px;
  font-weight: 700;
  cursor: pointer;

  .icon {
    width: auto;
    height: 40px;
    fill: $accent-color;
  }

}

.date-result {
  font-size: rem(16px);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
    height: 10px;
    background: url(../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center/100%;
  }

  &.is-open:after {
    transform: translateY(-50%) rotate(180deg);
  }

}

.pick-date {
  position: absolute;
  z-index: 3;
  left: -20px;
  width: 305px;
  top: 100%;
  margin-top: 1px;
  padding-top: 20px;
  background: $white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.calendar-prev,
.calendar-next {
  position: absolute;
  top: -6px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;

  svg {
    width: 22px;
    height: auto;
  }

}

.calendar-prev {
  left: 0;

  svg {
    transform: rotate(90deg);
  }

}

.calendar-next {
  right: 10px;

  svg {
    transform: rotate(-90deg);
  }

}

@include medium {

  .date-picker {
    margin-bottom: 0;
  }

}
