import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useSearchParams } from 'react-router-dom';

import * as styles from './style.module.scss';
import CountryContext from '../../react-web-ui/contexts/CountryContext';
import FormElement from '../../react-web-ui/components/FormElement';
import Checkbox from '../../react-web-ui/components/FormElement/Checkbox';
import LanguageSwitcher from '../LanguageSwitcher';
import SearchBar from '../SearchBar';
import Footer from '../Footer';
import Laptop from '../../assets/images/widget-laptop.png';
import Logo from '../SVGComponents/Logo';
import PlusOnly from '../SVGComponents/PlusOnly';
import RequestService from '../../networking/RequestService';
import BookRestaurantForm from '../BookRestaurantForm';
import MenuSection from '../MenuSection';
import ServicesSection from '../ServicesSection';
import ReviewsSection from '../ReviewsSection';

export default function Restaurant() {
  const { t, i18n } = useTranslation();
  const alert = useAlert();
  const countryContext = useContext(CountryContext);
  const [query, setQuery] = useState('');
  const [searchResult, setSearchResult] = useState(false);
  const [restaurantInfo, setRestaurantInfo] = useState([]);
  const [services, setServices] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [menu, setMenu] = useState(false);
  const [searchParams] = useSearchParams();
  const [widgetColor, setWidgetColor] = useState('#FFAE42');

  useEffect(() => {
    if (query === '') return;

    (new RequestService('client/places/details'))
      .setParams({
        slug: query.slug,
      })
      .send()
      .then((response) => {
        setRestaurantInfo(response.data);
      })
      .catch(() => null);
  }, [query, t]);

  useEffect(() => {
    if (searchParams.get('slug')) {
      (new RequestService('client/places/details'))
        .setParams({
          slug: searchParams.get('slug'),
        })
        .send()
        .then((response) => {
          setSearchResult(true);
          setRestaurantInfo(response.data);
          setQuery({
            name: response.data.name,
            slug: searchParams.get('slug'),
          });
        })
        .catch(() => null);
    }
    if (searchParams.get('color')) {
      setWidgetColor(`#${searchParams.get('color')}`);
    }
    if (searchParams.get('services')) {
      setServices(JSON.parse(searchParams.get('services')));
    }
    if (searchParams.get('reviews')) {
      setReviews(JSON.parse(searchParams.get('reviews')));
    }
    if (searchParams.get('menu')) {
      setMenu(JSON.parse(searchParams.get('menu')));
    }
  }, []);

  return (
    <div className="widget-landing">
      <div className={`page-header ${styles['restaurant-header']}`}>
        <div className={`inner-wrapper ${styles['restaurant-header-content']}`}>
          <span>
            {t('pageWidget:powered_by')}
            {' '}
            <Logo className={styles['logo']} />
          </span>
          <LanguageSwitcher locales={['en', countryContext.country.code.toLowerCase()]} />
        </div>
      </div>
      {!searchParams.get('slug') && (
        <div className="inner-wrapper">
          <SearchBar
            classes="page-heading-default"
            query={query}
            setQuery={setQuery}
            setSearchResult={setSearchResult}
          />
        </div>
      )}
      {!searchResult ? (
        <div className={`${styles['widget-info-block']} ${!searchResult ? styles['with-img'] : ''}`}>
          <div className="inner-wrapper">
            <img src={Laptop} alt="" />
          </div>
        </div>
      ) : restaurantInfo?.id && (
        <div className="inner-wrapper">
          <h1 className={styles['restaurant-name']}>
            <strong>{restaurantInfo.name}</strong>
            {restaurantInfo.subtitle ? '—' : ''}
            {restaurantInfo.subtitle}
          </h1>
          <h3>{t('pageWidget:settings')}</h3>
          <div className="options-inline">
            <Checkbox
              id="services"
              text={t('pageWidget:include_services')}
              checked={services}
              check={() => setServices(!services)}
            />
            <Checkbox
              id="reviews"
              text={t('pageWidget:include_reviews')}
              checked={reviews}
              check={() => setReviews(!reviews)}
            />
            <Checkbox
              id="menu"
              text={t('pageWidget:include_menu')}
              checked={menu}
              check={() => setMenu(!menu)}
            />
          </div>
          <div className={styles['widget-color']}>
            <h3>
              {t('pageWidget:preview')}
            </h3>
            <div className={styles['color-form-group']}>
              <span className={styles['label']}>{t('pageWidget:change_accent_color')}</span>
              <input
                type="color"
                id="widget-color"
                name="widget-color"
                value={widgetColor}
                onChange={(e) => setWidgetColor(e.target.value)}
              />
            </div>
          </div>
          <div
            className={services || reviews || menu ? 'lg-two-cols' : ''}
            style={{
              '--accent-color': widgetColor,
              '--accent-color-hover': `rgba(${parseInt(widgetColor.substr(1, 2), 16)}, ${parseInt(widgetColor.substr(3, 2), 16)}, ${parseInt(widgetColor.substr(5, 2), 16)}, 0.3)`,
            }}
          >
            <div className="col">
              <BookRestaurantForm
                restaurantId={restaurantInfo.id}
                restaurantInfo={restaurantInfo}
              />
            </div>
            {services || reviews || menu ? (
              <div className="col">
                {services && (
                  <ServicesSection
                    restaurantId={restaurantInfo.id}
                    name={restaurantInfo.name}
                    slug={restaurantInfo.slug}
                  />
                )}
                {reviews && (
                  <ReviewsSection
                    restaurantId={restaurantInfo.id}
                    name={restaurantInfo.name}
                    restaurantInfo={restaurantInfo}
                  />
                )}
                {menu && (
                  <MenuSection
                    info={restaurantInfo}
                    restaurantId={restaurantInfo.id}
                    name={restaurantInfo.name}
                  />
                )}
              </div>
            ) : null}
          </div>
          <h2 className="section-title">{t('pageWidget:how_to_install')}</h2>
          <h3>{t('pageWidget:include_css')}</h3>
          <div className={styles['copy-input']}>
            <FormElement
              id="widget-css"
              elementType="input"
              placeholder="Script"
              value={`<link rel="stylesheet" href="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.css">`}
              changed={() => { }}
              icon={<PlusOnly className="icon" />}
            />
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(`<link rel="stylesheet" href="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.css">`);
                alert.show(t('pageWidget:copied'), { type: 'success' });
              }}
            >
              {t('pageWidget:copy')}
            </button>
          </div>
          <h3>{t('pageWidget:include_widget')}</h3>
          <div className={styles['copy-input']}>
            <FormElement
              id="widget-div"
              elementType="input"
              placeholder="Div"
              value={`<div class="dineout-widget" ${widgetColor !== '#FFAE42' ? `data-color="${widgetColor}"` : ''} data-lang="${i18n.language}" data-type="app" data-id="${restaurantInfo.id}" ${services ? 'data-services="true"' : ''} ${reviews ? 'data-reviews="true"' : ''} ${menu ? 'data-menu="true"' : ''} data-os="referral-name.com"></div>`}
              changed={() => { }}
              icon={<PlusOnly className="icon" />}
            />
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(`<div class="dineout-widget" ${widgetColor !== '#FFAE42' ? `data-color="${widgetColor}"` : ''} data-lang="${i18n.language}" data-type="app" data-id="${restaurantInfo.id}" ${services ? 'data-services="true"' : ''} ${reviews ? 'data-reviews="true"' : ''} ${menu ? 'data-menu="true"' : ''} data-os="referral-name.com"></div>`);
                alert.show(t('pageWidget:copied'), { type: 'success' });
              }}
            >
              {t('pageWidget:copy')}
            </button>
          </div>
          <h3>{t('pageWidget:include_script')}</h3>
          <div className={styles['copy-input']}>
            <FormElement
              id="widget-script"
              elementType="input"
              placeholder="Script"
              value={`<script src="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.js"></script>`}
              changed={() => { }}
              icon={<PlusOnly className="icon" />}
            />
            <button
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(`<script src="https://book.dineout.${countryContext.country.code.toLowerCase()}/dist/index.js"></script>`);
                alert.show(t('pageWidget:copied'), { type: 'success' });
              }}
            >
              {t('pageWidget:copy')}
            </button>
          </div>
          <h3>{t('pageWidget:email_ivanka')}</h3>
        </div>
      )}
      <Footer />
    </div>
  );
}
