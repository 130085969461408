export default class Resource {
  constructor(id) {
    this._id = id;
  }

  set id(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  /**
   * Implementation required
   */
  static fromAPI() {
    throw new Error('You have to implement the method fromAPI()!');
  }

  /**
   * Implementation required
   */
  // eslint-disable-next-line class-methods-use-this
  plain() {
    throw new Error('You have to implement the method plain()!');
  }
}
