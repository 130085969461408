{
  "settings": "Postavke:",
  "preview": "Pretpregled:",
  "include_services": "Uključite usluge",
  "include_reviews": "Uključite ocjene i recenzije",
  "include_menu": "Uključite a la carte menu",
  "how_to_install": "Kako instalirati:",
  "include_css": "1. Uključite stilove widgeta na svoju stranicu u <head> htmla:",
  "include_widget": "2. Dodajte ovaj kôd tamo gdje želite da se Widget prikaže na vašoj stranici:",
  "include_script": "3. Uključite glavnu skriptu widgeta na svoju stranicu neposredno prije zatvaranja oznake <body>:",
  "copy": "kopija",
  "copied": "kopirano",
  "no_suggestions": "Nema prijedloga",
  "find_restaurant": "Pronađite obrazac za rezervaciju restorana",
  "find": "Pronaći",
  "type_restaurant_name": "Upišite naziv restorana",
  "powered_by": "Pokreće",
  "email_ivanka": "4. E-mail i.todorova@dineout.pl s domenom na koju želite staviti svoj widget.",
  "change_accent_color": "Promjena boje isticanja"
}
