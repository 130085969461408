import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="206.545" height="47.611" viewBox="0 0 206.545 47.611">
    <g transform="translate(-106.452 -159.254)">
      <g transform="translate(168.516 173.714)">
        <path d="M500.979,198.464a10.094,10.094,0,1,0,10.095,10.093A10.093,10.093,0,0,0,500.979,198.464Zm0,2.854a7.245,7.245,0,1,1-6.967,7.239A7.2,7.2,0,0,1,500.979,201.318Z" transform="translate(-411.173 -198.464)" fill="#2e2e2e" />
        <path d="M621.3,198.464v2.854h6.773V217.87H631.2V201.318h6.772v-2.854Z" transform="translate(-493.495 -198.464)" fill="#2e2e2e" />
        <path d="M574.059,199.245h-.023v10.592a5.694,5.694,0,1,1-11.375,0V198.464h-3.124v11a8.82,8.82,0,1,0,17.625,0v-11h-3.1Z" transform="translate(-454.507 -198.464)" fill="#2e2e2e" />
        <path d="M281.58,198.464h-6.839v20.187h6.839a10.094,10.094,0,0,0,0-20.187Zm0,17.334h-3.71V201.318h3.712a7.246,7.246,0,0,1,0,14.481Z" transform="translate(-274.741 -198.464)" fill="#2e2e2e" />
        <g transform="translate(23.183)"><path d="M339.946,198.464H337.6v20.187h3.126V198.464Z" transform="translate(-337.602 -198.464)" fill="#2e2e2e" /></g>
        <path d="M450.124,201.319v-2.856H435.046v20.187h15.2V215.8h-12.07v-5.947h11.276v-2.854H438.171v-5.675Z" transform="translate(-375.927 -198.464)" fill="#2e2e2e" />
        <path d="M381.179,199.245h-.014v13.984l-11.626-14.765h-2.874v20.187h3.126V203.533l11.874,15.118h2.626V198.464h-3.113Z" transform="translate(-332.764 -198.464)" fill="#2e2e2e" />
      </g>
      <path d="M132.638,159.254c-15.057,0-26.186,12.439-26.186,24.341,0,6.789,3.613,14.433,12.917,16.3a10.572,10.572,0,0,1-2.681,2.332,27.436,27.436,0,0,1-7.022,1.785,2.64,2.64,0,0,0,2.5,2.856c1.089,0,3.352-.949,5.492-2.571a25.327,25.327,0,0,0,10.577,2.1c17.25,0,26.411-13.13,26.411-25.53C154.645,169.765,147.258,159.254,132.638,159.254Zm-5.266,45.689a18.682,18.682,0,0,1-8.13-1.907,24.268,24.268,0,0,0,2.976-2.777c.543.035,1.1.06,1.671.06,15.593,0,19.4-13.569,19.4-18.092,0-2.2-.892-4.047-2.679-4.047a4.879,4.879,0,0,0-2.558.952,10.98,10.98,0,0,1,1.249,5.237c0,6.844-5.177,14.522-15.295,14.522-.215,0-.424-.007-.633-.014,8.768-11.292,5.041-27.09,17.178-32.9a1.246,1.246,0,0,0-.3-.833c-16.009,3.14-13.587,23.552-19.88,33.362-6.5-1.458-9.46-6.951-9.46-13.663,0-11.189,8.153-24.282,21.246-24.282,11.656,0,17.7,9.718,17.7,20.059C149.858,192.379,142.129,204.943,127.373,204.943Z" transform="translate(0)" fill="#2e2e2e" />
    </g>
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
