import BankDetails from './BankDetails';
import InvoiceDetails from './InvoiceDetails';
import Resource from './Resource';

export default class PayoutDetails extends Resource {
  static collectionKey = 'payout_details';

  constructor(id, bankDetails, invoiceDetails) {
    super(id);
    this._bankDetails = bankDetails;
    this._invoiceDetails = invoiceDetails;
  }

  get bankDetails() {
    return this._bankDetails;
  }

  get invoiceDetails() {
    return this._invoiceDetails;
  }

  static fromAPI(object) {
    return new PayoutDetails(
      object.id,
      BankDetails.fromAPI(object.bank_details),
      InvoiceDetails.fromAPI(object.invoice_details),
    );
  }
}
