import Resource from './Resource';

export default class ThirdPartyReview extends Resource {
  static collectionKey = 'third_party_reviews';

  constructor(
    id,
    placeId,
    source,
    reviewer,
    avatar,
    datetime,
    rating,
    recommendsRating,
    label,
    text,
    url,
  ) {
    super(id);
    this._placeId = placeId;
    this._source = source;
    this._reviewer = reviewer;
    this._avatar = avatar;
    this._datetime = datetime;
    this._rating = rating;
    this._recommendsRating = recommendsRating;
    this._label = label;
    this._text = text;
    this._url = url;
  }

  get placeId() {
    return this._placeId;
  }

  get source() {
    return this._source;
  }

  get reviewer() {
    return this._reviewer;
  }

  get avatar() {
    return this._avatar;
  }

  get datetime() {
    return new Date(this._datetime * 1000);
  }

  get rating() {
    return this._rating;
  }

  get recommendsRating() {
    return this._recommendsRating;
  }

  get label() {
    return this._label;
  }

  get text() {
    return this._text;
  }

  get url() {
    return this._url;
  }

  static fromAPI(object) {
    return new ThirdPartyReview(
      object.id,
      object.place_id,
      object.source,
      object.reviewer,
      object.avatar,
      object.datetime,
      object.rating,
      object.recommends_rating,
      object.label,
      object.text,
      object.url,
    );
  }
}
