import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import Tooltip from '../../Tooltip';

export const allergenMap = [
  'GLUTEN_KEY',
  'CRUSTACEANS_KEY',
  'EGGS_KEY',
  'FISH_KEY',
  'PEANUT_KEY',
  'SOYBEANS_KEY',
  'MILK_KEY',
  'NUTS_KEY',
  'CELERY_KEY',
  'MUSTARD_KEY',
  'SESAME_KEY',
  'SULPHUR_DIOXIDE_AND_SULPHIDES_KEY',
  'LUPIN_KEY',
  'MOLLUSCS_KEY',
];

/**
 * Menu item
 * @param icon
 * @param name
 * @param price
 * @param description
 * @param allergens
 * @returns {*}
 * @constructor
 */
export default function MenuItem({
  name,
  priceValue,
  description,
  allergens,
  settings,
}) {
  const { t } = useTranslation();
  const { itemName, itemDescription, price } = settings;
  return (
    <div className={styles['menu-item']}>
      <div className={styles['text']}>
        <p
          style={itemName ? {
            color: itemName.color,
            fontSize: `${itemName.fontSize.rem}rem`,
            lineHeight: itemName.lineHeight.px / itemName.fontSize.px,
            borderColor: itemName.borderColor,
            fontWeight: itemName.bold ? 700 : 500,
            fontStyle: itemName.italic ? 'italic' : 'normal',
            textDecoration: itemName.underline ? 'underline' : 'none',
            textAlign: itemName.textAlign,
            fontFamily: itemName.fontFamily.join(','),
          } : {}}
        >
          {name}
        </p>
        <small
          style={itemDescription ? {
            color: itemDescription.color,
            fontSize: `${itemDescription.fontSize.rem}rem`,
            lineHeight: itemDescription.lineHeight.px / itemDescription.fontSize.px,
            borderColor: itemDescription.borderColor,
            fontWeight: itemDescription.bold ? 700 : 500,
            fontStyle: itemDescription.italic ? 'italic' : 'normal',
            textDecoration: itemDescription.underline ? 'underline' : 'none',
            textAlign: itemDescription.textAlign,
            fontFamily: itemDescription.fontFamily.join(','),
          } : {}}
        >
          {description}
          {allergens && (
            <span className={styles['allergens']}>
              {allergens.map((allergen) => (
                <Tooltip key={allergen} title={t(allergen)} position="bottom">
                  <span>{allergenMap.indexOf(allergen) + 1}</span>
                </Tooltip>
              ))}
            </span>
          )}
        </small>
      </div>
      <div
        className={styles['price']}
        style={price ? {
          color: price.color,
          fontSize: `${price.fontSize.rem}rem`,
          lineHeight: price.lineHeight.px / price.fontSize.px,
          borderColor: price.borderColor,
          fontWeight: price.bold ? 700 : 500,
          fontStyle: price.italic ? 'italic' : 'normal',
          textDecoration: price.underline ? 'underline' : 'none',
          textAlign: price.textAlign,
          fontFamily: price.fontFamily.join(','),
        } : {}}
      >
        {priceValue}
      </div>
    </div>
  );
}

MenuItem.propTypes = {
  /** Name of menu item. */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /** Price of menu item. */
  priceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
  /** Description of menu item. */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  allergens: PropTypes.arrayOf(PropTypes.string),
  settings: PropTypes.oneOfType([PropTypes.object]),
};

MenuItem.defaultProps = {
  description: '',
  allergens: [],
  priceValue: '',
  settings: {},
};
