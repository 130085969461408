import Resource from './Resource';

export default class Elements extends Resource {
  static collectionKey = 'elements';

  constructor(
    id,
    name,
    people,
    x,
    y,
    minCapacity,
    maxCapacity,
    exists,
    type,
    bookable,
    bookingsCnt,
    rotation,
    width,
    height,
    resizable,
  ) {
    super(id);
    this._name = name;
    this._people = people;
    this._svg_x = x;
    this._svg_y = y;
    this._min_capacity = minCapacity;
    this._max_capacity = maxCapacity;
    this._exists = exists;
    this._type = type;
    this._bookable = bookable;
    this._bookings_cnt = bookingsCnt;
    this._rotation = rotation;
    this._width = width;
    this._height = height;
    this._resizable = resizable;
  }

  get name() {
    return this._name;
  }

  get people() {
    return this._people;
  }

  get x() {
    return this._svg_x;
  }

  get y() {
    return this._svg_y;
  }

  get minCapacity() {
    return this._min_capacity;
  }

  get maxCapacity() {
    return this._max_capacity;
  }

  get exists() {
    return this._exists;
  }

  get type() {
    return this._type;
  }

  get bookable() {
    return this._bookable;
  }

  get bookingsCnt() {
    return this._bookings_cnt;
  }

  get rotation() {
    return this._rotation;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get resizable() {
    return this._resizable;
  }

  static fromAPI(object) {
    return new Elements(
      object.id,
      object.name,
      object.people,
      object.x,
      object.y,
      object.min_capacity,
      object.max_capacity,
      true, // as it comes from api ;p
      object.type,
      object.bookable,
      object.bookings_cnt,
      object.rotation,
      object.width,
      object.height,
      object.resizable,
    );
  }

  plain() {
    return {
      id: this.id,
      name: this.name,
      minCapacity: this.minCapacity,
      maxCapacity: this.maxCapacity,
      type: this.type,
      exists: this.exists,
      rotation: this.rotation,
      bookable: this.bookable,
      bookingsCnt: this.bookingsCnt,
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      resizable: this.resizable,
    };
  }
}
