@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.checkbox {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  font-size: rem(15px);
  cursor: pointer;
  padding: 0;

  input {
    display: none;

    &:checked + .box::after {
      content: '';
      position: absolute;
      top: -3px;
      right: 0;
      width: 8px;
      height: 16px;
      border-bottom: 2px solid $accent-color;
      border-right: 2px solid $accent-color;
      transform: rotate(45deg);
    }

  }

  a {
    text-decoration: underline;
    color: $accent-color;
  }
  
  .text {
    display: block;
  }

  .box {
    position: relative;
    flex-shrink: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-right: 12px;
    background-color: $checkbox-bg;
  }

}
