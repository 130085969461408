import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import landingImg from '../assets/images/demo.jpg';
import Restaurant from '../components/Restaurant';
import AllRestaurants from '../components/AllRestaurants';

function Landing() {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    const script2 = document.createElement('script');

    script.setAttribute('src', '/js/cookie-consent.js');
    script.charset = 'utf-8';
    script2.innerHTML = 'cookieconsent.run({"notice_banner_type":"simple","consent_type":"express","palette":"light","language":"en","page_load_consent_levels":["strictly-necessary"],"notice_banner_reject_button_hide":false,"preferences_center_close_button_hide":false,"page_refresh_confirmation_buttons":false,"website_name":"Dineout"});';
    head.appendChild(script);

    script.onload = () => {
      head.appendChild(script2);
    };

    return () => {
      head.removeChild(script);
      head.removeChild(script2);
    };
  }, []);
  return (
    <Routes>
      <Route
        path="/"
        exact
        element={(
          <div
            style={{
              textAlign: 'center',
              marginTop: '10%',
            }}
          >
            <img
              src={landingImg}
              alt=""
              style={{
                maxWidth: '100%',
                width: '422px',
              }}
            />
          </div>
        )}
      />
      <Route
        path="/r/:slug"
        exact
        element={<Restaurant />}
      />
      <Route
        path="/all"
        exact
        element={<AllRestaurants />}
      />
    </Routes>
  );
}

export default Landing;
