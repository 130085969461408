import Resource from './Resource';

export default class MenuItem extends Resource {
  static collectionKey = 'items';

  constructor(id, name, description, price, isNew) {
    super(id);
    this._name = name;
    this._description = description;
    this._price = price;
    this._isNew = isNew;
  }

  get name() {
    return this._name;
  }

  get description() {
    return this._description;
  }

  get price() {
    return this._price;
  }

  get isNew() {
    return this._isNew;
  }

  static fromAPI(object) {
    return new MenuItem(
      object.id,
      object.name,
      object.description,
      object.price,
      object.is_new,
    );
  }
}
