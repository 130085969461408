{
  "section_offers_title": "also offers",
  "section_rating_title": "Rating and reviews about",
  "section_menu_title": "à la carte menu",
  "food": "Food",
  "service": "Service",
  "ambience": "Ambiance",
  "ratings": "ratings",
  "total_rating": "Overall rating",
  "read_more_reviews": "Read more reviews",
  "still_tasting": "We are still tasting this menu.",
  "accepts_bookings": "Accepts bookings",
  "chef_name": "Chef",
  "location": "Address",
  "contacts": "Contacts",
  "see_map": "See on a map"
}
