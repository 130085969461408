{
  "title": "Благодарение на Dineout - модерният начин да резервирате",
  "book_now": "РЕЗЕРВИРАЙТЕ",
  "book_again": "РЕЗЕРВИРАЙТЕ ОТНОВО",
  "special_offers": "Специални оферти",
  "book_event": "Кулинарни събития",
  "book_tasting": "Дегустационно меню",
  "mon": "Пон",
  "tue": "Вто",
  "wed": "Сря",
  "thu": "Чет",
  "fri": "Пет",
  "sat": "Съб",
  "sun": "Нед",
  "closed": "Затворено",
  "terms_of_use": "Общи условия",
  "visit_dineout": "Посетете",
  "GLUTEN_KEY": "Глутен",
  "CRUSTACEANS_KEY": "Ракообразни",
  "EGGS_KEY": "Яйца",
  "FISH_KEY": "Риба",
  "PEANUT_KEY": "Фъстъци",
  "SOYBEANS_KEY": "Соя",
  "MILK_KEY": "Млечни продукти",
  "NUTS_KEY": "Ядки",
  "CELERY_KEY": "Целина",
  "MUSTARD_KEY": "Горчица",
  "SESAME_KEY": "Сусам",
  "SULPHUR_DIOXIDE_AND_SULPHIDES_KEY": "Серен диоксид и сулфати",
  "LUPIN_KEY": "Лупин",
  "MOLLUSCS_KEY": "Мекотели",
  "restaurant_not_found": "Ресторант не е намерен",
  "loading": "Зарежда се..."
}
