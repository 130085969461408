import Resource from './Resource';

export default class SpecialEventSet extends Resource {
  static collectionKey = 'sets';

  constructor(
    id,
    name,
    price,
    drinksPrice,
    items,
    createdAt,
    translations,
  ) {
    super(id);
    this._name = name;
    this._price = price;
    this._drinksPrice = drinksPrice;
    this._items = items;
    this._createdAt = createdAt;
    this._translations = translations;
  }

  get name() {
    return this._name;
  }

  get price() {
    return this._price;
  }

  get drinksPrice() {
    return this._drinksPrice;
  }

  get items() {
    return this._items;
  }

  get createdAt() {
    return this._createdAt;
  }

  get translations() {
    return this._translations;
  }

  static fromAPI(object) {
    return new SpecialEventSet(
      object.id,
      object.name,
      object.price,
      object.drinks_price,
      object.items,
      object.created_at,
      object.translations,
    );
  }
}
