@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.restaurant-menu {
  margin-bottom: 25px;
  font-weight: 500;

  select {
    height: 38px;
    padding-bottom: 15px;
    font-size: rem(18px);
  }

  .form-group {
    margin-bottom: 24px;
  }

}
