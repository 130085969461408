import Resource from './Resource';

export default class ShiftRules extends Resource {
  static collectionKey = null;

  constructor(
    perPerson,
    perReservation,
  ) {
    super(null);
    this._perPerson = perPerson;
    this._perReservation = perReservation;
  }

  get perPerson() {
    if (this._perPerson != null) {
      return {
        min: this._perPerson.min,
        noShowFee: this._perPerson.no_show_fee,
        lateCancellationFee: this._perPerson.late_cancellation_fee,
        lateCancellationThreshold: this._perPerson.late_cancellation_threshold,
      };
    }
    return null;
  }

  get perReservation() {
    if (this._perReservation != null) {
      return {
        min: this._perReservation.min,
        noShowFee: this._perReservation.no_show_fee,
        lateCancellationFee: this._perReservation.late_cancellation_fee,
        lateCancellationThreshold: this._perReservation.late_cancellation_threshold,
      };
    }
    return null;
  }

  static fromAPI(object) {
    return new ShiftRules(
      object.per_person,
      object.per_reservation,
    );
  }
}
