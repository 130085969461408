{
  "booking_error": "Nieudana rezerwacja, sprawdź wprowadzone dane",
  "booking_success": "Udana rezerwacja",
  "check_terms_error": "Prosimy o przeczytanie i zaakceptowanie naszych zasad i warunków użytkowania",
  "email": "Email",
  "email_error_empty": "Proszę, dodaj swój adres mailowy",
  "email_error_invalid": "Proszę wpisz poprawny adres mailowy",
  "first_name": "Imię",
  "first_name_error_empty": "Proszę, wpisz swoje imię",
  "last_name": "Nazwisko",
  "last_name_error_empty": "Proszę, wpisz swoje nazwisko",
  "phone": "Numer telefonu",
  "phone_error_empty": "Proszę, dodaj numer telefonu",
  "phone_error_invalid": "Proszę, dodaj prawidłowy numer telefonu",
  "read_and_accept": "Przeczytałem & zaakceptowałem ",
  "terms_and_conditions": "zasady i warunki użytkowania",
  "select_date": "Wybierz datę",
  "success_send": "Wiadomość wysłano poprawnie.",
  "notes": "Dodatkowe prośby do rezerwacji"
}
