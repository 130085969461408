import PropTypes, { string, object } from 'prop-types';
import React from 'react';

/**
 * Checkbox that can be used in forms.
 * @param id
 * @param text
 * @param value
 * @param checked
 * @param check
 * @param errors
 * @param className
 * @returns {*}
 * @constructor
 */

export default function Checkbox({
  id,
  text,
  value,
  checked,
  check,
  errors,
  className,
}) {
  return (
    <>
      <label htmlFor={id} className={`checkbox ${className}`}>
        <input onChange={check} type="checkbox" value={value} id={id} name={id} checked={checked} />
        <span className="box" />
        <span className="text">{text}</span>
      </label>
      {errors && errors.map((error) => <p key={error} className="error">{error}</p>)}
    </>
  );
}

Checkbox.propTypes = {
  /** Id for the checkbox. */
  id: PropTypes.string.isRequired,
  /** text for the checkbox. */
  text: PropTypes.oneOfType([string, object]).isRequired,
  /** value for the checkbox. */
  value: PropTypes.string,
  className: PropTypes.string,
  /** Sets checked attribute on the checkbox. */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /** Function to executo on check. */
  check: PropTypes.func,
  /** Error message */
  errors: PropTypes.arrayOf(PropTypes.string),
};

Checkbox.defaultProps = {
  errors: [],
  value: '',
  className: '',
  checked: false,
  check: null,
};
