import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import PageHeading from '../../react-web-ui/components/PageHeading';
import AutocompleteInput from './AutocompleteInput';
import Button from '../Button';

function SearchBar({
  classes,
  // disabled,
  query,
  setQuery,
  setSearchResult,
}) {
  const { t } = useTranslation();

  return (
    <PageHeading
      classes={classes}
      title={t('pageWidget:find_restaurant')}
      content={(
        <form action="" className={styles['search-form']}>
          <div className={styles['search-inputs']}>
            <div className={styles['wrapper-with-padding']}>
              <AutocompleteInput
                searchQuery={query}
                setSearchQuery={setQuery}
              />
              <Button
                classes={`btn btn-accent ${styles['btn']}`}
                onClick={(event) => {
                  if (event) event.preventDefault();
                  setSearchResult(false);
                  setTimeout(() => {
                    setSearchResult(query !== '');
                  }, 500);
                }}
                text={t('pageWidget:find')}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
}

SearchBar.propTypes = {
  classes: PropTypes.string.isRequired,
  // disabled: PropTypes.bool,
  query: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setQuery: PropTypes.func,
  setSearchResult: PropTypes.func,
};

SearchBar.defaultProps = {
  // disabled: false,
  query: null,
  setQuery: null,
  setSearchResult: null,
};

export default SearchBar;
