import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className={className}
  >
    <g id="Layer_x0020_1">
      <path
        className="st0"
        d="M40.7,17.8V35c0,1-0.5,2-1,2.8c-0.8,0.5-1.8,1-2.8,1H13c-1,0-2-0.5-2.8-1c-0.5-0.8-1-1.8-1-2.8V17.8
      c0-1,0.5-2,1-2.8c0.8-0.8,1.8-1,2.8-1h2.8v-1.5l0,0c0-1,0.8-1.8,1.7-1.8c1,0,1.8,0.8,1.8,1.8l0,0V14h11.5v-1.5l0,0
      c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8l0,0V14H37c1,0,2,0.2,2.8,1C40.2,15.8,40.7,16.8,40.7,17.8z M30.7,28.5h3.5v3.8h-3.5V28.5z
       M23.2,28.5h3.5v3.8h-3.5V28.5z M30.7,22h3.5v3.7h-3.5C30.7,25.8,30.7,22,30.7,22z M23.2,22h3.5v3.7h-3.5
      C23.2,25.8,23.2,22,23.2,22z M15.7,28.5h3.5v3.8h-3.5V28.5z M15.7,22h3.5v3.7h-3.5C15.7,25.8,15.7,22,15.7,22z M30.7,18.8v-1.2
      H19.2v1.2h-3.5v-1.2H13c0,0,0,0-0.3,0c0,0,0,0,0,0.2V35c0,0,0,0,0,0.2c0.3,0,0.3,0,0.3,0h24c0,0,0,0,0.2,0c0-0.2,0-0.2,0-0.2V17.8
      c0-0.2,0-0.2,0-0.2c-0.2,0-0.2,0-0.2,0h-2.8v1.2H30.7z"
      />
    </g>
  </svg>
);

Calendar.propTypes = {
  className: PropTypes.string,
};

Calendar.defaultProps = {
  className: '',
};

export default Calendar;
