import MenuCategory from './MenuCategory';

export default class MenuCategoryWithTranslations extends MenuCategory {
  static collectionKey = 'categories';

  constructor(id, name, items, translations) {
    super(id, name, items);
    this._translations = translations;
  }

  get translations() {
    return this._translations;
  }

  static fromAPI(object) {
    return new MenuCategoryWithTranslations(
      object.id,
      object.name,
      object.items,
      object.translations,
    );
  }
}
