{
  "booking_error": "Unsuccessful booking, please check the entered data",
  "booking_success": "Successful booking",
  "check_terms_error": "Please, read and accept the Terms and Conditions.", 
  "email": "Email",
  "email_error_empty": "Please, enter an email address",
  "email_error_invalid": "Please, enter a valid email address",     
  "first_name": "Name",
  "first_name_error_empty": "Please, enter your first name",
  "last_name": "Surname",
  "last_name_error_empty": "Please, enter a surname",
  "phone": "Phone",
  "phone_error_empty": "Please, enter your phone number",
  "phone_error_invalid": "Please, enter a valid phone number",
  "read_and_accept": "I have read & accept the ",
  "terms_and_conditions": "terms & conditions",
  "select_date": "Select date",
  "select_time": "Select time",
  "success_send": "Your message was sent successfully.",
  "notes": "Additional requests regarding your booking"
}
