import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as btnStyles from '../../Button/style.module.scss';

export default function Services({
  specialOffers,
  tasting,
  event,
  slug,
}) {
  const { t } = useTranslation();
  return (
    <>
      {specialOffers.length > 0 && (
        <div className="discount-offers">
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}/special-offers/${slug}`}
            className={`${btnStyles['btn']} ${btnStyles['btn-outline']} ${btnStyles['btn-discount']}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="special-offers"
          >
            {t('special_offers')}
          </a>
        </div>
      )}
      {tasting.length > 0 && (
        <div className="tasting-offers">
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}/book-tasting/${slug}`}
            className={`${btnStyles['btn']} ${btnStyles['btn-outline']} ${btnStyles['btn-tasting']}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="tasting-menus"
          >
            {t('book_tasting')}
          </a>
        </div>
      )}
      {event.length > 0 && (
        <div className="event-offers">
          <a
            href={`https://${process.env.REACT_APP_DOMAIN}/events/${slug}`}
            className={`${btnStyles['btn']} ${btnStyles['btn-outline']} ${btnStyles['btn-event']}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="special-events"
          >
            {t('book_event')}
          </a>
        </div>
      )}
    </>
  );
}

Services.propTypes = {
  specialOffers: PropTypes.oneOfType([PropTypes.array]),
  tasting: PropTypes.oneOfType([PropTypes.array]),
  event: PropTypes.oneOfType([PropTypes.array]),
  slug: PropTypes.string,
};

Services.defaultProps = {
  specialOffers: [],
  tasting: [],
  event: [],
  slug: '',
};
