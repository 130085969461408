import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/bg';
import 'moment/locale/pl';
import 'moment/locale/hr';
import 'moment/locale/it';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerSingleDateController } from 'react-dates';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import useOutsideClick from '../../react-web-ui/hooks/useOutsideClick';
import ArrowDown from '../SVGComponents/ArrowDown';
import Calendar from '../SVGComponents/Calendar';

/**
 * Date picker
 * @param date
 * @param handleDate
 * @param dates
 * @returns {*}
 * @constructor
 */
export default function DatePicker({
  date,
  handleDate,
  dates,
}) {
  const ref = useRef();
  const { i18n, t } = useTranslation();
  const [openCalendar, setOpenCalendar] = useState(false);

  moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  useEffect(() => {
    moment.locale(i18n.language === 'en' ? 'en-gb' : i18n.language);
  }, [i18n.language]);

  useOutsideClick(ref, () => {
    if (openCalendar) {
      setOpenCalendar(false);
    }
  });

  return (
    <div
      className={styles['date-picker']}
      role="button"
      tabIndex="0"
      data-testid="date-wrapper"
      onClick={() => {
        setOpenCalendar(!openCalendar);
      }}
      onKeyPress={() => {
        setOpenCalendar(!openCalendar);
      }}
    >
      <Calendar className={styles['icon']} />
      <div data-testid="date-result" className={`${styles['date-result']} ${openCalendar ? styles['is-open'] : ''}`}>
        {date ? date.format('DD.MM.YYYY') : t('forms:select_date')}
      </div>
      {openCalendar && (
        <div ref={ref} data-testid="date-popup" className={styles['pick-date']}>
          <DayPickerSingleDateController
            onDateChange={(event) => {
              handleDate(event);
              setOpenCalendar(false);
            }}
            date={date ? date.locale(i18n.language) : null}
            isOutsideRange={(day) => day.isBefore(moment().startOf('day'))}
            isDayBlocked={(day) => {
              if (dates.length < 1) return false;
              return !dates.some((dateItem) => day.isSame(dateItem, 'day'));
            }}
            focused
            numberOfMonths={1}
            navPrev={(
              <button className={styles['calendar-prev']} type="button">
                <ArrowDown />
              </button>
            )}
            navNext={(
              <button className={styles['calendar-next']} type="button">
                <ArrowDown />
              </button>
            )}
            noBorder
            hideKeyboardShortcutsPanel
          />
        </div>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  /**
   * Default date
   */
  date: PropTypes.instanceOf(moment),
  /**
   * Function on date click
   */
  handleDate: PropTypes.func.isRequired,
  /**
   * dates
   */
  dates: PropTypes.arrayOf(PropTypes.string),
};

DatePicker.defaultProps = {
  dates: [],
  date: null,
};
