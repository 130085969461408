import Resource from './Resource';

export default class Statement extends Resource {
  static collectionKey = 'statements';

  constructor(
    type,
    points,
    extra,
    createdAt,
  ) {
    super(null);
    this._type = type;
    this._points = points;
    this._extra = extra;
    this._createdAt = createdAt;
  }

  get type() {
    return this._type;
  }

  get points() {
    return this._points;
  }

  get extra() {
    return this._extra;
  }

  get createdAt() {
    return this._createdAt;
  }

  static fromAPI(object) {
    return new Statement(
      object.type,
      object.points,
      object.extra,
      object.created_at,
    );
  }
}
