import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';
import CountryContext from '../../react-web-ui/contexts/CountryContext';

export default function Footer() {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);

  return (
    <footer className={styles['page-footer']}>
      <div className="inner-wrapper">
        <ul>
          <li>
            <a href={`https://dineout.${countryContext.country.code.toLowerCase()}/terms-of-service`}>
              {t('terms_of_use')}
            </a>
          </li>
          <li>
            <a href={`https://dineout.${countryContext.country.code.toLowerCase()}/`}>
              {`${t('visit_dineout')} dineout.${countryContext.country.code.toLowerCase()}`}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
