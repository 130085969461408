{
  "section_offers_title": "također nudi",
  "section_rating_title": "Ocjene i recenzije ",
  "section_menu_title": "jelovnik à la carte ",
  "food": "Hrana",
  "service": "Usluga",
  "ambience": "Ambijent",
  "ratings": "ocjene",
  "total_rating": "Ukupna ocjena",
  "read_more_reviews": "Pročitajte više recenzija",
  "still_tasting": "Još uvijek kušamo ovaj meni.",
  "accepts_bookings": "Prihvaća rezervacije",
  "chef_name": "Kuhar",
  "location": "Adresa",
  "contacts": "Kontakti",
  "see_map": "Pogledajte na karti"
}
