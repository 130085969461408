import Place from './Place';
import Resource from './Resource';

export default class Block extends Resource {
  static collectionKey = 'blocks';

  constructor(id, start, end, isManual, place) {
    super(id);
    this._start = start;
    this._end = end;
    this._isManual = isManual;
    this._place = place;
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  get isManual() {
    return this._isManual;
  }

  get place() {
    return this._place;
  }

  static fromAPI(object) {
    return new Block(
      object.id,
      object.start,
      object.end,
      object.is_manual,
      Place.fromAPI(object.place),
    );
  }
}
