import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RequestService from '../../networking/RequestService';
import Place from '../../networking/resources/Place';
import Reviews from './Reviews';

export default function ReviewsSection({
  restaurantId,
  name,
  restaurantInfo,
}) {
  const { t } = useTranslation();
  const [restaurantReviews, setRestaurantReviews] = useState([]);

  useEffect(() => {
    if (restaurantId) {
      (new RequestService('client/reviews/place'))
        .setParams({
          place_id: restaurantId,
          sort: 'submitted',
        })
        .send()
        .then((response) => {
          setRestaurantReviews(response.data.filter((item) => item.text || item.content));
        })
        .catch(() => null);
    }
  }, [restaurantId]);

  return (
    <section className="section">
      <h2 className="section-title">
        {`${t('pageRestaurantProfile:section_rating_title')} `}
        <strong>{name}</strong>
      </h2>
      <Reviews restaurantReviews={restaurantReviews} slug={restaurantInfo.slug} />
    </section>
  );
}

ReviewsSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
  restaurantInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]),
};

ReviewsSection.defaultProps = {
  restaurantId: '',
  name: '',
  restaurantInfo: {},
};
