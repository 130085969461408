export default class Banner {
  constructor(style, title, description, action, button, image) {
    this._style = style;
    this._title = title;
    this._description = description;
    this._action = action;
    this._button = button;
    this._image = image;
  }

  get style() {
    return this._style;
  }

  get title() {
    return this._title;
  }

  get description() {
    return this._description;
  }

  get action() {
    return this._action;
  }

  get button() {
    return this._button;
  }

  get image() {
    return this._image;
  }

  get isActionWeb() {
    return this._action.type === 'web';
  }

  get hasBanner() {
    return this._title !== undefined;
  }

  static fromAPI(object) {
    return new Banner(
      object.style,
      object.title,
      object.description,
      object.action,
      object.button,
      object.image,
    );
  }
}
