import Resource from './Resource';

export default class SpecialEventSetForBooking extends Resource {
  static collectionKey = 'chosen_sets';

  constructor(id, name, price, items, quantity, createdAt) {
    super(id);
    this._name = name;
    this._price = price;
    this._items = items;
    this._quantity = quantity;
    this._createdAt = createdAt;
  }

  get name() {
    return this._name;
  }

  get price() {
    return this._price;
  }

  get items() {
    return this._items;
  }

  get quantity() {
    return this._quantity;
  }

  get createdAt() {
    return this._createdAt;
  }

  static fromAPI(object) {
    return new SpecialEventSetForBooking(
      object.id,
      object.name,
      object.price,
      object.items,
      object.quantity,
      object.created_at,
    );
  }
}
