{
  "section_offers_title": "oferuje także",
  "section_rating_title": "Ocena i opinie o",
  "section_menu_title": "menu à la carte",
  "food": "Jedzenie",
  "service": "Serwis",
  "ambience": "Atmosfera",
  "ratings": "oceny",
  "total_rating": "Ogólna ocena",
  "read_more_reviews": "Przeczytaj więcej recenzji",
  "still_tasting": "Wciąż tworzymy to menu.",
  "accepts_bookings": "Przyjmuje rezerwacje",
  "chef_name": "Szef kuchni",
  "location": "Adres",
  "contacts": "Kontakty",
  "see_map": "Zobacz na mapie"
}
