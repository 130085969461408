@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.pin-icon {
  width: 36px;
  margin-left: -6px;
  margin-right: 0;
}

.clear-query {
  @include reset-btn;

  position: absolute;
  right: 0;
  top: 50%;
  width: 10px;

  .icon {
    width: 30px;
    fill: $primary-color;
    background-color: transparent;
  }

}

.autocomplete-input {

  .error {
    position: absolute;
    margin-top: -5px;
  }

  .icon-search {
    fill: $white;
  }

}

@include large {

  .autocomplete-input {
    width: 100%;
    margin-bottom: 0;
    padding-right: 30px;
    border-right: 1px solid $border-color;

    input {
      height: 36px;
    }

    .error {
      margin-top: 0;
    }

  }

}