import Resource from './Resource';

export default class ReviewReply extends Resource {
  static collectionKey = 'replies';

  constructor(
    id,
    content,
    submittedOn,
  ) {
    super(id);
    this._content = content;
    this._submittedOn = submittedOn;
  }

  get content() {
    return this._content;
  }

  get submittedOn() {
    return new Date(this._submittedOn * 1000);
  }

  static fromAPI(object) {
    return new ReviewReply(
      object.id,
      object.content,
      object.submitted_on,
    );
  }
}
