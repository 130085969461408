{
  "settings": "Ustawienia:",
  "preview": "Pretpregled:",
  "include_services": "Zawiera usługi",
  "include_reviews": "Zawiera oceny i recenzje",
  "include_menu": "Zawiera menu",
  "how_to_install": "Jak dokonać instalacji:",
  "include_css": "1. Zamieść style widgetu na swojej stronie.",
  "include_widget": "2. Dodaj ten kod w miejscu, w którym chcesz, aby widget był wyświetlany na Twojej stronie:",
  "include_script": "3. Dodaj na swojej stronie główny skrypt Widgetu:",
  "copy": "SKOPIUJ",
  "copied": "Skopiowano",
  "no_suggestions": "Nie ma żadnych propozycji",
  "find_restaurant": "Sprawdź formularz rezerwacji restauracji",
  "find": "Sprawdź",
  "type_restaurant_name": "Wpisz nazwę restauracji",
  "powered_by": "Zasilane przez",
  "email_ivanka": "4. E-mail i.todorova@dineout.pl s domenom na koju želite staviti svoj widget.",
  "change_accent_color": "Zmień kolor podświetlenia"
}
