import common from './common.json';
import forms from './forms.json';
import pageBookings from './pageBookings.json';
import pageRestaurantProfile from './pageRestaurantProfile.json';
import pageWidget from './pageWidget.json';

export default {
  common,
  forms,
  pageBookings,
  pageRestaurantProfile,
  pageWidget,
};
