import City from './City';
import Resource from './Resource';

export default class NeighbourhoodManager extends Resource {
  static collectionKey = 'neighbourhoods';

  constructor(id, name, city) {
    super(id);
    this._name = name;
    this._city = city;
  }

  get name() {
    return this._name;
  }

  get city() {
    return this._city;
  }

  static fromAPI(object) {
    return new NeighbourhoodManager(
      object.id,
      object.name,
      City.fromAPI(object.city),
    );
  }
}
