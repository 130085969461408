@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.search-form {
  background-color: $white;
  box-shadow: $box-shadow;
}

.btn {
  margin-top: 0;
}

.search-inputs {
  background-color: $white;
  box-shadow: $box-shadow;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: auto;
    height: 35px;
    transform: translateY(-50%);
    fill: $white;
    background-color: $accent-color;
    border-radius: 4px;
  }

  input {
    height: 50px;
    padding-left: 54px;
    border-bottom: 0;
  }

  .btn {
    width: 100%;
  }

}

.wrapper-with-padding {
  padding: 20px;
}

.mobile-search-info {
  display: flex;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  outline: none;

  .icon {
    width: 40px;
    flex-shrink: 0;
    margin-right: 5px;
    margin-left: -10px;
  }

}

.guests-wrapper {
  position: relative;
  margin-bottom: 30px;
  padding-left: 54px;
}

@include large {

  .search-form {
    width: calc(100% - 320px);
  }

  .search-inputs {
    box-shadow: none;

    input {
      padding-left: 45px;
    }

    .btn {
      width: auto;
    }

  }

  .wrapper-with-padding {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .guests-wrapper {
    flex-shrink: 0;
    width: 185px;
    margin-bottom: 0;
    margin-left: 30px;
    padding-right: 30px;
    padding-left: 45px;
  }

}

@include larger {

  .search-inputs {
    display: flex;
  }

}