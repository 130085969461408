import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { menuImg } from '@dineoutltd/utilities';

import { Icon } from '@dineoutltd/components';
import * as styles from './style.module.scss';
import RequestService from '../../networking/RequestService';
import StillTasting from '../../assets/images/still-tasting.svg';
import Menu from './Menu';
import Place from '../../networking/resources/Place';

export default function MenuSection({
  name,
  info,
}) {
  const { t } = useTranslation();
  const [restaurantMenu, setRestaurantMenu] = useState([]);
  const [restaurantMenuGallery, setRestaurantMenuGallery] = useState([]);
  const refSlider = useRef();

  const settingsSlider = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    swipeToSlide: true,
    draggable: true,
    speed: 500,
  };

  const next = () => {
    const slider = refSlider.current;
    slider.slickNext();
  };

  const previous = () => {
    const slider = refSlider.current;
    slider.slickPrev();
  };

  useEffect(() => {
    let endpoint = '';
    if (info.alacarteMenu.type === null) return;

    if (info.alacarteMenu.type === 'IMAGE') {
      endpoint = 'client/alacarte-menu/image';
    }
    if (info.alacarteMenu.type === 'TEXT') {
      endpoint = 'client/alacarte-menu/text';
    }

    const requestMenuItems = new RequestService(endpoint);

    requestMenuItems.setParams({
      place_id: info.id,
    })
      .send()
      .then((response) => {
        if (info.alacarteMenu.type === 'IMAGE') {
          const newItems = response.data.map((item) => (menuImg(info.id, item, 600, '')));
          setRestaurantMenuGallery(newItems);
        }
        if (info.alacarteMenu.type === 'TEXT') {
          setRestaurantMenu(response.data);
        }
      })
      .catch(() => null);
  }, [t, info.alacarteMenu.type]);

  return (
    <section data-testid="menu-section" className="section">
      <h2 className="section-title">
        <strong>{name}</strong>
        {` ${t('pageRestaurantProfile:section_menu_title')} `}
      </h2>
      {restaurantMenu.length > 0 && (
        <Menu restaurantMenu={restaurantMenu} settings={info.alacarteMenu.styles} />
      )}
      {restaurantMenuGallery.length > 0 && (
      <div className={styles['menu-gallery-wrapper']}>

        <div className={styles['menu-gallery-controls']}>
          <button
            className={styles['btn-prev']}
            type="button"
            onClick={previous}
          >
            <Icon size={15} name="arrowDown" className="icon" />
          </button>
          <button
            className={styles['btn-next']}
            type="button"
            onClick={next}
          >
            <Icon size={15} name="arrowDown" className="icon" />
          </button>
        </div>
        <Slider
          ref={refSlider}
          {...settingsSlider}
        >
          {restaurantMenuGallery.map((image) => (
            <React.Fragment
              key={`${image}-img`}
            >
              <div
                className={styles['menu-gallery-item']}
              >
                <img
                  src={image}
                  alt=""
                />
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </div>
      )}

      {restaurantMenu.length === 0 && restaurantMenuGallery.length === 0 && (
        <div className={styles['no-menu']}>
          <img src={StillTasting} alt="" />
          {t('pageRestaurantProfile:still_tasting')}
        </div>
      )}
    </section>
  );
}

MenuSection.propTypes = {
  name: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]),
};

MenuSection.defaultProps = {
  name: '',
  info: {},
};
