import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BookRestaurantForm from '../components/BookRestaurantForm';
import MenuSection from '../components/MenuSection';
import ReviewsSection from '../components/ReviewsSection';
import ServicesSection from '../components/ServicesSection';
import RequestService from '../networking/RequestService';

function App({ domElement }) {
  const lang = domElement.getAttribute('data-lang');
  const services = domElement.getAttribute('data-services');
  const reviews = domElement.getAttribute('data-reviews');
  const menu = domElement.getAttribute('data-menu');
  const restaurantId = domElement.getAttribute('data-id');
  const color = domElement.getAttribute('data-color') || '#FFAE42';
  const { t, i18n } = useTranslation();
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
    (new RequestService('client/places/details'))
      .setParams({
        place_id: restaurantId,
      })
      .send()
      .then((response) => {
        setRestaurantInfo(response.data);
      })
      .catch(() => setNotFound(true));
  }, [i18n, lang, restaurantId]);

  if (notFound) {
    return (
      <div>
        {t('restaurant_not_found')}
      </div>
    );
  }

  if (restaurantInfo) {
    return (
      <div
        className={services || reviews || menu ? 'lg-two-cols' : ''}
        style={{
          '--accent-color': color,
          '--accent-color-hover': `rgba(${parseInt(color.substr(1, 2), 16)}, ${parseInt(color.substr(3, 2), 16)}, ${parseInt(color.substr(5, 2), 16)}, 0.3)`,
        }}
      >
        <div className="col">
          <BookRestaurantForm
            restaurantId={restaurantId}
            restaurantInfo={restaurantInfo}
            lang={lang}
          />
        </div>
        {services || reviews || menu ? (
          <div className="col">
            {services && (
            <ServicesSection
              restaurantId={restaurantId}
              name={restaurantInfo.name}
              slug={restaurantInfo.slug}
            />
            )}
            {reviews && (
            <ReviewsSection
              restaurantId={restaurantId}
              name={restaurantInfo.name}
              restaurantInfo={restaurantInfo}
            />
            )}
            {menu && (
            <MenuSection
              info={restaurantInfo}
              restaurantId={restaurantId}
              name={restaurantInfo.name}
            />
            )}
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      {t('loading')}
    </div>
  );
}

App.propTypes = {
  domElement: PropTypes.oneOfType([PropTypes.object, PropTypes.element]).isRequired,
};

export default App;
