import Resource from './Resource';

export default class User extends Resource {
  static collectionKey = 'users';

  constructor(
    id,
    email,
    firstName,
    lastName,
    phone,
    createdAt,
    points,
    facebookId,
    googleId,
    appleId,
    language,
    subscribeRestaurantsEmails,
    subscribeRestaurantsNotifications,
    subscribeEventsEmails,
    subscribeEventsNotifications,
    subscribeDiscountsEmails,
    subscribeDiscountsNotifications,
    subscribeMonthlyStatementEmails,
    subscribeMonthlyStatementNotifications,
    subscribeLunchDealsEmails,
    subscribeLunchDealsNotifications,
    subscribedCities,
    subscribedCuisines,
    countryPreference,
    isNew,
    privileges,
    allergens,
    gender,
    thumb,
    birthday,
    rating,
  ) {
    super(id);
    this._email = email;
    this._firstName = firstName;
    this._lastName = lastName;
    this._phone = phone;
    this._createdAt = createdAt;
    this._points = points;
    this._facebookId = facebookId;
    this._googleId = googleId;
    this._appleId = appleId;
    this._language = language;
    this._subscribeRestaurantsEmails = subscribeRestaurantsEmails;
    this._subscribeRestaurantsNotifications = subscribeRestaurantsNotifications;
    this._subscribeEventsEmails = subscribeEventsEmails;
    this._subscribeEventsNotifications = subscribeEventsNotifications;
    this._subscribeDiscountsEmails = subscribeDiscountsEmails;
    this._subscribeDiscountsNotifications = subscribeDiscountsNotifications;
    this._subscribeMonthlyStatementEmails = subscribeMonthlyStatementEmails;
    this._subscribeMonthlyStatementNotifications = subscribeMonthlyStatementNotifications;
    this._subscribeLunchDealsEmails = subscribeLunchDealsEmails;
    this._subscribeLunchDealsNotifications = subscribeLunchDealsNotifications;
    this._subscribedCities = subscribedCities;
    this._subscribedCuisines = subscribedCuisines;
    this._countryPreference = countryPreference;
    this._isNew = isNew;
    this._privileges = privileges;
    this._allergens = allergens;
    this._gender = gender;
    this._thumb = thumb;
    this._birthday = birthday;
    this._rating = rating;
  }

  get id() {
    return this._id;
  }

  get email() {
    return this._email;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get phone() {
    return this._phone;
  }

  get createdAt() {
    return this._createdAt;
  }

  get points() {
    return this._points;
  }

  set points(points) {
    this._points = points;
  }

  get facebookId() {
    return this._facebookId;
  }

  get googleId() {
    return this._googleId;
  }

  get appleId() {
    return this._appleId;
  }

  get language() {
    return this._language;
  }

  get subscribeRestaurantsEmails() {
    return this._subscribeRestaurantsEmails;
  }

  get subscribeRestaurantsNotifications() {
    return this._subscribeRestaurantsNotifications;
  }

  get subscribeEventsEmails() {
    return this._subscribeEventsEmails;
  }

  get subscribeEventsNotifications() {
    return this._subscribeEventsNotifications;
  }

  get subscribeDiscountsEmails() {
    return this._subscribeDiscountsEmails;
  }

  get subscribeDiscountsNotifications() {
    return this._subscribeDiscountsNotifications;
  }

  get subscribeMonthlyStatementEmails() {
    return this._subscribeMonthlyStatementEmails;
  }

  get subscribeMonthlyStatementNotifications() {
    return this._subscribeMonthlyStatementNotifications;
  }

  get subscribeLunchDealsEmails() {
    return this._subscribeLunchDealsEmails;
  }

  get subscribeLunchDealsNotifications() {
    return this._subscribeLunchDealsNotifications;
  }

  get subscribedCities() {
    return this._subscribedCities;
  }

  get subscribedCuisines() {
    return this._subscribedCuisines;
  }

  get countryPreference() {
    return this._countryPreference;
  }

  get isNew() {
    return this._isNew;
  }

  get privileges() {
    return this._privileges;
  }

  get allergens() {
    return this._allergens;
  }

  get gender() {
    return this._gender;
  }

  get thumb() {
    return this._thumb;
  }

  get birthday() {
    return this._birthday;
  }

  get rating() {
    return this._rating;
  }

  static fromAPI(object) {
    return new User(
      object.id,
      object.email,
      object.first_name,
      object.last_name,
      object.phone,
      object.created_at,
      object.points,
      object.facebook_id,
      object.google_id,
      object.apple_id,
      object.language,
      object.subscribe_restaurants_emails,
      object.subscribe_restaurants_notifications,
      object.subscribe_events_emails,
      object.subscribe_events_notifications,
      object.subscribe_discounts_emails,
      object.subscribe_discounts_notifications,
      object.subscribe_monthly_statement_emails,
      object.subscribe_monthly_statement_notifications,
      object.subscribe_lunch_deals_emails,
      object.subscribe_lunch_deals_notifications,
      object.subscribed_cities,
      object.subscribed_cuisines,
      object.country_preference,
      object.is_new,
      object.privileges,
      object.allergens,
      object.gender,
      object.thumb,
      object.birthday,
      object.rating ? object.rating : {},
    );
  }
}
