/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FuzzySearch from 'react-fuzzy';

import * as styles from './style.module.scss';
import Magnifier from '../../SVGComponents/Magnifier';
import RequestService from '../../../networking/RequestService';
import CountryContext from '../../../react-web-ui/contexts/CountryContext';

function AutocompleteInput({ searchQuery, setSearchQuery }) {
  const { t } = useTranslation();
  const countryContext = useContext(CountryContext);
  const [allSuggestions, setAllSuggestions] = useState([]);

  useEffect(() => {
    (new RequestService('client/countries/suggestions'))
      .setParams({
        country_id: countryContext.country.id,
      })
      .send()
      .then((response) => {
        setAllSuggestions(response.data);
      })
      .catch(() => null);
  }, []);

  return (
    <div className={`form-group ${styles['autocomplete-input']}`}>
      <div className="input-wrapper">
        <Magnifier className={`icon ${styles['icon-search']}`} />
        <FuzzySearch
          threshold={0.3}
          list={allSuggestions}
          keys={['name', 'tokens']}
          keyForDisplayName="name"
          width="100%"
          inputProps={{
            defaultValue: typeof searchQuery === 'string'
              ? searchQuery
              : searchQuery.name,
            onChange: (e) => {
              setSearchQuery(e.target.value);
            },
          }}
          inputStyle={{
            padding: 0,
            border: 0,
            fontSize: '14px',
          }}
          inputWrapperStyle={{
            paddingLeft: 45,
            boxShadow: 0,
            backgroundColor: 'transparent',
          }}
          placeholder={t('pageWidget:type_restaurant_name')}
          listWrapperStyle={{
            position: 'absolute',
            left: 34,
          }}
          listItemStyle={{
            color: 'inherit',
            borderColor: '#e6e6e6',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            border: 'none',
          }}
          selectedListItemStyle={{
            color: 'inherit',
            backgroundColor: '#f1f3f4',
          }}
          isDropdown
          onSelect={(newSelectedItem) => {
            setSearchQuery(newSelectedItem);
          }}
          resultsTemplate={(props, state, styles, clickHandler) => state.results.map((val, i) => {
            const style = state.selectedIndex === i
              ? styles.selectedResultStyle
              : styles.resultsStyle;
            return (
              <div
                key={i}
                role="button"
                style={style}
                tabIndex="0"
                onClick={() => clickHandler(i)}
              >
                <strong>{val.name}</strong>
                {`${val.address !== null ? `, ${val.address}` : ''}`}
                {`${val.neighbourhood.city !== null ? `, ${val.neighbourhood.city.name}` : ''}`}
              </div>
            );
          })}
        />
      </div>
    </div>
  );
}

AutocompleteInput.propTypes = {
  setSearchQuery: PropTypes.func,
  searchQuery: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

AutocompleteInput.defaultProps = {
  setSearchQuery: null,
  searchQuery: null,
};

export default AutocompleteInput;
