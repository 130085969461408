import Resource from './Resource';

export default class Collection extends Resource {
  static collectionKey = 'collections';

  constructor(id, name, places, thumb) {
    super(id);
    this._name = name;
    this._places = places;
    this._thumb = thumb;
  }

  get name() {
    return this._name;
  }

  get places() {
    return this._places;
  }

  get thumb() {
    return this._thumb;
  }

  static fromAPI(object) {
    return new Collection(
      object.id,
      object.name,
      object.places,
      object.thumb,
    );
  }
}
