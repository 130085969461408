import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className={className}
  >

    <g id="Layer_x0020_1">
      <path
        className="st0"
        d="M16.6,30.9l5.8-5.9l-5.8-5.8l0,0c-0.3-0.3-0.6-0.8-0.6-1.3c0-1,0.8-1.9,1.9-1.9c0.5,0,0.9,0.2,1.3,0.6l5.8,5.8
      l5.9-5.8l0,0c0.3-0.3,0.8-0.6,1.3-0.6c1,0,1.9,0.8,1.9,1.9c0,0.5-0.2,0.9-0.6,1.3l0,0l-5.9,5.8l5.9,5.9l0,0
      c0.3,0.3,0.6,0.8,0.6,1.3c0,1-0.8,1.9-1.9,1.9c-0.5,0-0.9-0.2-1.3-0.6l0,0l-5.9-5.9l-5.8,5.9c-0.3,0.3-0.8,0.6-1.3,0.6
      c-1,0-1.9-0.8-1.9-1.9C16,31.7,16.2,31.2,16.6,30.9C16.6,30.9,16.6,30.9,16.6,30.9z"
      />
    </g>
  </svg>
);

Close.propTypes = {
  className: PropTypes.string,
};

Close.defaultProps = {
  className: '',
};

export default Close;
