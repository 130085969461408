import moment from 'moment';
import Resource from './Resource';

export default class PlaceWorkingHours extends Resource {
  static collectionKey = 'working';

  constructor(id, openingHours, closingHours) {
    super(id);
    this._openingHours = openingHours;
    this._closingHours = closingHours;
  }

  get openingHours() {
    return this._openingHours;
  }

  get closingHours() {
    return this._closingHours;
  }

  get workingHours() {
    const workingHoursText = [];
    let opening = [];
    let closing = [];

    if (this._openingHours) {
      opening = [...this._openingHours];
      closing = [...this._closingHours];

      opening.push(opening.shift());
      closing.push(closing.shift());

      const openingHoursFormatted = opening.map(
        (secs) => moment.utc(secs * 1000).format('HH:mm'),
      );

      const closingHoursFormatted = closing.map(
        (secs) => moment.utc(secs * 1000).format('HH:mm'),
      );

      const days = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];
      const workHours = [];

      for (let i = 0; i < 7; i += 1) {
        if (openingHoursFormatted[i] === '00:00' && closingHoursFormatted[i] === '00:00') {
          workHours.push('Closed');
        } else {
          workHours.push(`${openingHoursFormatted[i]} - ${closingHoursFormatted[i]}`);
        }
      }

      const result = [];
      let currentDays = '';

      for (let j = 0; j < workHours.length; j += 1) {
        if (workHours[j] === workHours[j + 1]) {
          currentDays += `${days[j]} `;
        } else {
          result.push({
            day: `${currentDays} ${days[j]}`,
            hours: workHours[j],
          });
          currentDays = '';
        }
      }

      let arr = [];

      for (let k = 0; k < result.length; k += 1) {
        arr = result[k].day.split(' ');
        arr = arr.filter((item) => item);
        if (arr[0] !== arr[arr.length - 1]) {
          result[k].day = `${arr[0]} - ${arr[arr.length - 1]}`;
        } else {
          result[k].day = `${arr[0]}`;
        }
        workingHoursText.push(`${result[k].day}:  ${result[k].hours}`);
      }
    }
    return (workingHoursText);
  }

  static fromAPI(object) {
    return new PlaceWorkingHours(
      object.id,
      object.opening_hours,
      object.closing_hours,
    );
  }
}
