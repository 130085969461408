import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { dateTimeFormat, timeFormat } from '@dineoutltd/utilities';
import { useTranslation } from 'react-i18next';

function RulesText({
  shiftRules,
  currency,
  time,
  perPerson,
}) {
  const { t } = useTranslation();

  const threshold = time ? moment
    .utc((time - shiftRules.lateCancellationThreshold) * 1000)
    .format(dateTimeFormat) : moment
    .utc((shiftRules.lateCancellationThreshold) * 1000)
    .format(timeFormat);

  return (
    <>
      <p>
        {t(`pageBookings:per_${perPerson ? 'person' : 'reservation'}`, { amount: `${shiftRules.noShowFee / 100}${currency}` })}
      </p>
      <p>{t('pageBookings:please_note')}</p>
      <ul>
        <li>{t('pageBookings:cancel_for_free', { threshold })}</li>
        {shiftRules.lateCancellationThreshold && (
          <li>
            {t(`pageBookings:late_cancellation_threshold_per_${perPerson ? 'person' : 'reservation'}`,
              {
                threshold,
                amount: `${shiftRules.lateCancellationFee / 100}${currency}`,
              })}
          </li>
        )}
        <li>{t('pageBookings:no_show_charge', { amount: `${shiftRules.noShowFee / 100}${currency}` })}</li>
      </ul>
    </>
  );
}

RulesText.propTypes = {
  shiftRules: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currency: PropTypes.string.isRequired,
  time: PropTypes.number,
  perPerson: PropTypes.bool,
};

RulesText.defaultProps = {
  time: null,
  perPerson: false,
};

export default RulesText;
