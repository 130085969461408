import Country from './Country';
import Gift from './Gift';
import Marketing from './Marketing';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';

export default class UserManager extends Resource {
  static collectionKey = 'users';

  constructor(
    id,
    firstName,
    lastName,
    email,
    password,
    passwordV2,
    phone,
    isSubscribed,
    country,
    countryPreference,
    source,
    placesManagedCount,
    position,
    marketing,
    privileges,
    isPrimary,
    phoneVerifiedAt,
    taxId,
    billingAddress,
    gifts,
  ) {
    super(id);
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._phone = phone;
    this._password = password;
    this._passwordV2 = passwordV2;
    this._isSubscribed = isSubscribed;
    this._country = country;
    this._countryPreference = countryPreference;
    this._source = source;
    this._placesManagedCount = placesManagedCount;
    this._position = position;
    this._marketing = marketing;
    this._privileges = privileges;
    this._isPrimary = isPrimary;
    this._phoneVerifiedAt = phoneVerifiedAt;
    this._taxId = taxId;
    this._billingAddress = billingAddress;
    this._gifts = gifts;
  }

  get id() {
    return this._id;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get email() {
    return this._email;
  }

  get password() {
    return this._password;
  }

  get passwordV2() {
    return this._passwordV2;
  }

  get phone() {
    return this._phone;
  }

  get isSubscribed() {
    return this._isSubscribed;
  }

  get country() {
    return this._country;
  }

  get countryPreference() {
    return this._countryPreference;
  }

  get source() {
    return this._source;
  }

  get placesManagedCount() {
    return this._placesManagedCount;
  }

  get position() {
    return this._position;
  }

  get marketing() {
    return this._marketing;
  }

  get privileges() {
    return this._privileges;
  }

  get isPrimary() {
    return this._isPrimary;
  }

  get phoneVerifiedAt() {
    return this._phoneVerifiedAt;
  }

  get taxId() {
    return this._taxId;
  }

  get billingAddress() {
    return this._billingAddress;
  }

  get gifts() {
    return this._gifts;
  }

  static fromAPI(object) {
    return new UserManager(
      object.id,
      object.first_name,
      object.last_name,
      object.email,
      object.password,
      object.password_v2,
      object.phone,
      object.is_subscribed,
      object.country ? Country.fromAPI(object.country) : {},
      object.country_preference,
      object.source,
      object.places_managed_count,
      object.position,
      object.marketing ? Marketing.fromAPI(object.marketing) : {},
      object.privileges,
      object.is_primary,
      object.phone_verified_at,
      object.tax_id,
      object.billing_address,
      object.gifts ? new ResourceCollection(Gift).make(object) : [],
    );
  }
}
