import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './style.module.scss';

export default function FormElement({
  elementType,
  id,
  name,
  value,
  changed,
  errors,
  placeholder,
  selectOptions,
  disabled,
  inputType = 'text',
}) {
  let element = null;

  switch (elementType) {
    case ('input'):
      element = (
        <input
          name={name || id}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={changed}
          disabled={disabled}
        />
      );
      break;
    case ('select'):
      element = (
        <select
          name={name || id}
          onChange={changed}
          disabled={disabled}
          value={value}
          data-testid={name || id}
        >
          {selectOptions.map((option, index) => (
            <option
              key={option.value}
              value={option.value}
              data-testid={`val${index + 1}`}
            >
              {option.text}
            </option>
          ))}
        </select>
      );
      break;
    default:
      element = (
        <input
          name={name || id}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={changed}
          disabled={disabled}
        />
      );
  }

  return (
    <div
      className={`
        ${styles['form-group']}
        ${errors.length ? 'error' : ''}
      `}
    >
      {element}
      {errors && errors.map((error) => <p key={error} className="error">{error}</p>)}
    </div>
  );
}

FormElement.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  /** Placeholder of the form element. */
  placeholder: PropTypes.string.isRequired,
  /** Type of the form element
  * Supports: "input", "textarea", "select". */
  elementType: PropTypes.string.isRequired,
  /** The type attribute of an input element */
  inputType: PropTypes.string,
  /** The value of the input element */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** expects onChange function */
  changed: PropTypes.func.isRequired,
  /** Disabled input */
  disabled: PropTypes.bool,
  /** Error message */
  errors: PropTypes.arrayOf(PropTypes.string),
  /** The select selectOptions */
  selectOptions: PropTypes.arrayOf(PropTypes.object),
};

FormElement.defaultProps = {
  errors: [],
  id: '',
  name: '',
  inputType: 'text',
  selectOptions: [],
  disabled: false,
};
