import Resource from './Resource';

export default class BankDetails extends Resource {
  static collectionKey = 'bank_details';

  constructor(id, beneficiary, iban, bic) {
    super(id);
    this._beneficiary = beneficiary;
    this._iban = iban;
    this._bic = bic;
  }

  get beneficiary() {
    return this._beneficiary;
  }

  get iban() {
    return this._iban;
  }

  get bic() {
    return this._bic;
  }

  static fromAPI(object) {
    return new BankDetails(
      object.id,
      object.beneficiary,
      object.iban,
      object.bic,
    );
  }
}
