import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import * as btnStyles from '../../Button/style.module.scss';
import * as styles from '../style.module.scss';
import FormElement from '../../FormElement';
import Button from '../../Button';
import useForm from '../../../react-web-ui/hooks/useForm';
import validate from './validation';
import Checkbox from '../../FormElement/Checkbox';
import RequestService from '../../../networking/RequestService';
import Spinner from '../../Spinner';
import CustomPhoneInput from '../../CustomPhoneInput';

let createBookingRequest = null;

export default function BookingFormFields({
  time,
  guests,
  setShowSuccess,
  restaurantId,
  outside,
  lang,
  setBooking,
}) {
  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  } = useForm(
    // eslint-disable-next-line no-use-before-define
    submit,
    validate,
  );

  function submit() {
    if (createBookingRequest !== null) {
      createBookingRequest.source.cancel();
    }
    setLoading(true);
    const bookingParams = {
      place_id: restaurantId,
      outside,
      dt: time,
      people: guests,
      contact_phone: values.phone,
      contact_email: values.email_book,
      contact_name: `${values.first_name_book} ${values.last_name_book}`,
      first_name: values.first_name_book,
      last_name: values.last_name_book,
    };

    if (values.notes_book) {
      bookingParams.notes = values.notes_book;
    }

    createBookingRequest = (new RequestService('guest/bookings/create'))
      .setParams(bookingParams);

    createBookingRequest
      .send()
      .then((response) => {
        // alert.show(t('forms:booking_success'), { type: 'success' });
        setShowSuccess(true);
        setBooking(response.data);
      })
      .catch(() => alert.show(t('forms:booking_error'), { type: 'error' }))
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {/* <section data-testid="additional-notes" className={styles['bookings-info-section']}> */}
      {/*  <h3 className="section-sub-title">{t('pageBookings:aditional_requests')}</h3> */}
      {/*  <FormElement */}
      {/*    id="notes_book" */}
      {/*    elementType="input" */}
      {/*    placeholder={t('forms:notes')} */}
      {/*    value={values.notes_book || ''} */}
      {/*    errors={errors.notes_book} */}
      {/*    changed={handleChange} */}
      {/*  /> */}
      {/* </section> */}
      <section data-testid="personal-information" className={styles['bookings-info-section']}>
        <h3 className="section-sub-title">{t('pageBookings:personal_information')}</h3>
        <div className={styles['two-columns-form-group']}>
          <FormElement
            id="first_name_book"
            elementType="input"
            placeholder={t('forms:first_name')}
            value={values.first_name_book || ''}
            errors={errors.first_name_book}
            changed={handleChange}
          />
          <FormElement
            id="last_name_book"
            elementType="input"
            placeholder={t('forms:last_name')}
            value={values.last_name_book || ''}
            errors={errors.last_name_book}
            changed={handleChange}
          />
          <FormElement
            id="email_book"
            elementType="input"
            inputType="email"
            placeholder={t('forms:email')}
            value={values.email_book || ''}
            errors={errors.email_book}
            changed={handleChange}
          />
          <CustomPhoneInput
            values={values}
            setValues={setValues}
            errors={errors.phone}
            noLabel
            lang={lang}
          />
        </div>
        <Checkbox
          id={`accept_terms_book_${Math.floor(Math.random() * Math.floor(100))}`}
          name="accept_terms_book"
          text={(
            <span>
              {t('forms:read_and_accept')}
              <a
                href={`https://${process.env.REACT_APP_DOMAIN}/terms-of-service`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('forms:terms_and_conditions')}
              </a>
            </span>
          )}
          value={`${values.accept_terms_book}`}
          checked={values.accept_terms_book}
          check={handleChange}
          errors={errors.accept_terms_book}
          testId="checkbox"
        />
      </section>
      <Button
        text={loading ? (
          <>
            <Spinner />
            {t('loading')}
          </>
        ) : t('book_now')}
        classes={loading ? btnStyles['loading-btn'] : ''}
        disabled={loading}
        onClick={handleSubmit}
      />
    </>
  );
}

BookingFormFields.propTypes = {
  time: PropTypes.oneOfType([PropTypes.any]),
  restaurantId: PropTypes.string.isRequired,
  outside: PropTypes.bool.isRequired,
  guests: PropTypes.number,
  setShowSuccess: PropTypes.func,
  lang: PropTypes.string,
  setBooking: PropTypes.func,
};

BookingFormFields.defaultProps = {
  time: null,
  guests: 0,
  setShowSuccess: null,
  lang: null,
  setBooking: null,
};
