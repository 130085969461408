import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { timeFormat } from '@dineoutltd/utilities';
import styles from './style.module.scss';
import Place from '../../networking/resources/Place';
import RulesText from '../RulesText';

function ShiftNotesAndRules({
  date,
  time,
  shifts,
  count,
  restaurantInfo,
}) {
  const { t } = useTranslation();
  return date && time && shifts?.filter((shift) => shift.day === date.day()).map((shift) => {
    let currentTimeSeconds = moment
      .duration(moment.unix(time).utc().format(timeFormat))
      .asSeconds();
    if (currentTimeSeconds < 25200) {
      currentTimeSeconds += 86400;
    }

    if (shift.start <= currentTimeSeconds
      && shift.end >= currentTimeSeconds
      && (shift.notes || shift.rules)) {
      return (
        <Fragment key={shift.id}>
          {shift.notes && (
            <p>
              <strong className="text-accent">
                *
                {shift.notes}
              </strong>
            </p>
          )}
          {shift.rules && (
            count >= shift.rules.perPerson?.min
            || count >= shift.rules.perReservation?.min
          ) && (
            <div className={`gray-block full-width ${styles['shift-rules']}`}>
              {shift.rules.perPerson
                && (
                <RulesText
                  shiftRules={shift.rules.perPerson}
                  currency={restaurantInfo.city.country.currency}
                  date={date}
                  time={time}
                  perPerson
                />
                )}
              {shift.rules.perReservation
                && (
                <RulesText
                  shiftRules={shift.rules.perReservation}
                  currency={restaurantInfo.city.country.currency}
                  date={date}
                  time={time}
                />
                )}
              <p>{t('pageBookings:funds_release')}</p>
              <a target="_blank" rel="noreferrer" className="text-accent" href={`https://${process.env.REACT_APP_DOMAIN}/shift-rules/${restaurantInfo.slug}`}>
                {t('pageBookings:more_info')}
              </a>
            </div>
          )}
        </Fragment>
      );
    }
    return null;
  });
}

ShiftNotesAndRules.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
  time: PropTypes.number.isRequired,
  shifts: PropTypes.oneOfType([PropTypes.array]).isRequired,
  count: PropTypes.number.isRequired,
  restaurantInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Place)]),
};
export default ShiftNotesAndRules;
