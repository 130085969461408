import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import TableWithoutSalon from './TableWithoutSalon';

export default class TableCombination extends Resource {
  static collectionKey = 'combinations';

  constructor(
    id,
    name,
    minCapacity,
    maxCapacity,
    tables,
  ) {
    super(id);
    this._name = name;
    this._min_capacity = minCapacity;
    this._max_capacity = maxCapacity;
    this._tables = tables;
  }

  get name() {
    return this._name;
  }

  get minCapacity() {
    return this._min_capacity;
  }

  get maxCapacity() {
    return this._max_capacity;
  }

  get tables() {
    return this._tables;
  }

  static fromAPI(object) {
    return new TableCombination(
      object.id,
      object.name,
      object.min_capacity,
      object.max_capacity,
      (new ResourceCollection(TableWithoutSalon)).make(object),
    );
  }

  plain() {
    return {
      id: this.id,
      name: this.name,
      minCapacity: this.minCapacity,
      maxCapacity: this.maxCapacity,
      tables: this.tables.map((table) => table.plain()),
    };
  }
}
