{
  "settings": "Настройки:",
  "preview": "Преглед:",
  "include_services": "Показване на услугите на ресторанта",
  "include_reviews": "Показване на оценки и ревюта",
  "include_menu": "Показване на основното меню",
  "how_to_install": "Как да инсталирате уиджета:",
  "include_css": "1. Добавете стиловете на своя Widget, задължително в таг <head>:",
  "include_widget": "2. Добавете този код, където искате да се визуализира уиджета",
  "include_script": "3. Добавете задължителния код за уиджета на страницата си, непосредствено преди да затворите <body> тага.",
  "copy": "КОПИРАЙ",
  "copied": "Копирано",
  "no_suggestions": "Няма препоръки",
  "find_restaurant": "Намерете формата за резервация на даден ресторант",
  "find": "Намерете",
  "type_restaurant_name": "Попълнете име на ресторант",
  "powered_by": "Предоставено от",
  "email_ivanka": "4. Изпратете имейл на: i.todorova@dineout.bg като посчите домейна, на който искате да инсталирате widget.",
  "change_accent_color": "Сменете основния цвят"
}
