import PropTypes, { string, object } from 'prop-types';
import React from 'react';

import * as styles from './style.module.scss';

/**
 * Checkbox that can be used in forms.
 * @param id
 * @param name
 * @param text
 * @param value
 * @param checked
 * @param check
 * @param errors
 * @returns {*}
 * @constructor
 */

export default function Checkbox({
  id,
  name,
  text,
  value,
  checked,
  check,
  errors,
  testId,
}) {
  return (
    <>
      <label data-testid={`${testId}-label`} htmlFor={id} className={styles['checkbox']} onChange={check}>
        <input type="checkbox" value={value} id={id} name={name} defaultChecked={checked} data-testid={testId} />
        <span className={styles['box']} />
        <span className={styles['text']}>{text}</span>
      </label>
      {errors && errors.map((error) => <p key={error} className="error">{error}</p>)}
    </>
  );
}

Checkbox.propTypes = {
  /** Id for the checkbox. */
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  /** text for the checkbox. */
  text: PropTypes.oneOfType([string, object]).isRequired,
  /** value for the checkbox. */
  value: PropTypes.string,
  /** Sets checked attribute on the checkbox. */
  checked: PropTypes.bool,
  /** Function to executo on check. */
  check: PropTypes.func,
  /** Error message */
  errors: PropTypes.arrayOf(PropTypes.string),
  testId: PropTypes.string,
};

Checkbox.defaultProps = {
  errors: [],
  value: '',
  name: '',
  checked: false,
  check: null,
  testId: '',
};
