{
  "section_offers_title": "offre anche",
  "section_rating_title": "Recensioni e valutazioni su",
  "section_menu_title": "Menu alla carta",
  "food": "Cibo",
  "service": "Servizio",
  "ambience": "Ambiente",
  "ratings": "valutazione",
  "total_rating": "Valutazione generale",
  "read_more_reviews": "Leggi ulteriori recensioni",
  "still_tasting": "Stiamo testando questo menu",
  "accepts_bookings": "Si accettano prenotazioni",
  "chef_name": "Chef",
  "location": "Posti per Dineout",
  "contacts": "Contatti",
  "see_map": "Guarda sulla mappa"
}
