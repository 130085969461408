{
  "aditional_requests": "Допълнителни бележки",
  "inside": "Вътре",
  "outside": "Вън",
  "personal_information": "Лична информация",
  "no_time_slots": "Няма свободни маси за това търсене.",
  "seating_tooltip": "Изеберете къде да бъдете настанени.",
  "seating_tooltip_inside": "Ресторантът има места само вътре",
  "select_date_and_guests": "Изберете дата и брой гости, за да видите възможните часове",
  "select_time": "Изберете час",
  "pay": "Плащане",
  "per_person": "Ресторантът изисква гаранция с кредитна карта за тази резервация в размер на {{amount}} лева на човек.",
  "per_reservation": "Ресторантът изисква гаранция с кредитна карта за тази резервация в размер на {{amount}} лева. ",
  "min_people": "Ако резервацията ви е за повече от {{min}} души.",
  "late_cancellation_threshold_per_person": "Ако анулирате след {{threshold}}, ресторантът ще начисли такса за късно анулиране: {{amount}} на човек.",
  "late_cancellation_threshold_per_reservation": "Ако анулирате след {{threshold}}, ресторантът ще начисли такса за късно анулиране: {{amount}}.",
  "more_info": "Повече информация",
  "transactions_text": "Моля, имайте предвид, че вашата резервация не е завършена, докато не бъде извършено плащането.",
  "please_note": "Моля, имайте предвид, че:",
  "cancel_for_free": "Можете да анулирате тази резервация безплатно до {{threshold}}",
  "no_show_charge":"Ако не се явите за резервацията, ресторантът ще начисли такса от: {{amount}}",
  "funds_release": "Вашите средства ще бъдат освободени при успешна резервация или, в случай че анулирате, в момента, в който анулирате резервацията си. Може да отнеме до 7 дни, докато обратната транзакция се покаже на кредитната ви карта."
}
