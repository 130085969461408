@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.form-group {
  margin-bottom: 10px;

  input,
  select {
    width: 100%;
    height: 46px;
    padding: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $primary-color;
    background-color: transparent;
    outline: none;

    &[disabled] {
      background-color: $gray;
    }

  }

  select {
    padding-right: 28px;
  }

  &.error {

    input,
    select,
    textarea {
      border-color: $error-color;
    }

    p {
      margin: 0;
    }

  }


}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background: url(../../react-web-ui/assets/images/icons/ArrowDown.svg) no-repeat center right;
}