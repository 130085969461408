import React from 'react';
import PropTypes from 'prop-types';

const User = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className={className}
  >

    <g id="Layer_x0020_1">
      <path
        className="st0"
        d="M25,10.5c2.5,0,4.7,1,6.3,2.6c1.6,1.6,2.6,3.8,2.6,6.2c0,2.5-1,4.7-2.6,6.3c-1.6,1.6-3.8,2.6-6.3,2.6
      s-4.6-1-6.2-2.6c-1.6-1.6-2.6-3.8-2.6-6.3c0-2.4,1-4.6,2.6-6.2C20.4,11.5,22.6,10.5,25,10.5z M39.2,37.7c0,1-0.8,1.8-1.8,1.8
      c-1,0-1.7-0.8-1.7-1.8v0v-0.1c0-1.2-0.5-2.3-1.3-3.1c-0.8-0.8-1.9-1.3-3.1-1.3H18.6c-1.2,0-2.3,0.5-3,1.3c-0.8,0.8-1.3,1.8-1.3,3
      l0,0v0c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8v0c0-2.2,0.9-4.1,2.3-5.5c1.4-1.4,3.4-2.3,5.5-2.3h12.7c2.2,0,4.1,0.9,5.5,2.3
      s2.3,3.4,2.3,5.5L39.2,37.7C39.2,37.7,39.2,37.7,39.2,37.7z M28.8,15.6c-1-1-2.3-1.5-3.8-1.5c-1.5,0-2.8,0.6-3.8,1.5
      s-1.5,2.3-1.5,3.8c0,1.5,0.6,2.8,1.5,3.8c1,1,2.3,1.6,3.8,1.6c1.5,0,2.8-0.6,3.8-1.5c1-1,1.6-2.3,1.6-3.8
      C30.3,17.9,29.7,16.5,28.8,15.6z"
      />
    </g>
  </svg>
);

User.propTypes = {
  className: PropTypes.string,
};

User.defaultProps = {
  className: '',
};

export default User;
