@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.btn {
  display: block;
  width: 100%;
  margin-top: 20px;
  padding: 10px 40px;
  border-style: solid;
  border-width: 2px;
  color: $white;
  font-weight: bold;
  font-size: rem(16px);
  text-align: center;
  cursor: pointer;
  outline-color: transparent;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $accent-color;
  border-color: $accent-color;
  transition: box-shadow 0.2s;

  &[disabled] {
    background: $light-gray;
    border-color: $light-gray;
    color: $white;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 6px $accent-color;
    background-color: $accent-color;
  }

}

.btn-outline {
  background: transparent;
  color: $accent-color;

  &:hover,
  &:focus {
    background-color: transparent;
  }

}

.btn-discount {
  color: $discount-color;
  border-color: $discount-color;

  &:hover,
  &:focus {
    box-shadow: 0 0 6px $discount-color;
  }

}

.btn-tasting {
  color: $tasting-color;
  border-color: $tasting-color;

  &:hover,
  &:focus {
    box-shadow: 0 0 6px $tasting-color;
  }

}

.btn-event {
  color: $event-color;
  border-color: $event-color;

  &:hover,
  &:focus {
    box-shadow: 0 0 6px $event-color;
  }

}

.loading-btn {
  position: relative;
  padding-left: 50px;
}