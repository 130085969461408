@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.restaurant-header {
  position: static;
  height: auto;
}

.restaurant-info-wrapper {
  margin-bottom: 20px;
  padding-top: 20px;
  background-color: $gray;
}

.restaurant-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.restaurant-name {
  float: left;
  font-size: rem(24px);
  font-weight: normal;
}

.working-hours {
  display: block;
}

.loader-wrapper {
  position: relative;
  height: 100vh;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

@include large {

  .restaurant-info {
    flex-wrap: nowrap;
  }

}