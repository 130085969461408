import PropTypes from 'prop-types';
import React, { useState } from 'react';

import * as styles from './style.module.scss';
import MenuItem from '../MenuItem';
import FormElement from '../../FormElement';

/**
 * Menu
 * @param restaurantMenu
 * @param settings
 * @returns {*}
 * @constructor
 */
export default function Menu({
  restaurantMenu,
  settings,
}) {
  const [selectedMenu, setSelectedMenu] = useState(restaurantMenu[0].id);
  let categories;

  if (restaurantMenu) {
    categories = restaurantMenu.map((menu) => ({
      value: menu.id,
      text: menu.name,
    }));
  }

  return (
    <div data-testid="restaurant-menu" className={styles['restaurant-menu']}>
      <FormElement
        id="menu-select"
        elementType="select"
        placeholder="Choose one"
        value={selectedMenu}
        changed={(e) => setSelectedMenu(e.target.value)}
        selectOptions={categories}
      />
      {restaurantMenu.map((category) => {
        if (selectedMenu === category.id) {
          return (
            <div key={category.name} className="menu-items-wrapper">
              {category.items.map((item) => (
                <MenuItem
                  key={item.id}
                  name={item.name}
                  description={item.description}
                  priceValue={item.price}
                  settings={settings}
                />
              ))}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

Menu.propTypes = {
  restaurantMenu: PropTypes.oneOfType([PropTypes.array]).isRequired,
  settings: PropTypes.oneOfType([PropTypes.object]),
};

Menu.defaultProps = {
  settings: {},
};
