import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './lang/en';
import bg from './lang/bg';
import pl from './lang/pl';
import hr from './lang/hr';
import it from './lang/it';

const allowedLanguages = ['en', 'bg', 'pl', 'hr', 'it'];

let lng = 'en';

const storageLanguage = localStorage.getItem('i18nextLng');
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}

const resources = {
  en,
  bg,
  pl,
  hr,
  it,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng,
    defaultNS: 'common',
    resources,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
