import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import SpecialEventSet from './SpecialEventSet';

export default class SpecialEvent extends Resource {
  static collectionKey = 'special_events';

  constructor(
    id,
    name,
    isMandatory,
    featureSets,
    description,
    lastSaleDate,
    tags,
    label,
    prebook,
    status,
    allocation,
    thumb,
    intervals,
    sets,
    slug,
    translations,
  ) {
    super(id);
    this._name = name;
    this._isMandatory = isMandatory;
    this._featureSets = featureSets;
    this._description = description;
    this._lastSaleDate = lastSaleDate;
    this._tags = tags;
    this._label = label;
    this._prebook = prebook;
    this._status = status;
    this._allocation = allocation;
    this._thumb = thumb;
    this._intervals = intervals;
    this._sets = sets;
    this._slug = slug;
    this._translations = translations;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get isMandatory() {
    return this._isMandatory;
  }

  get featureSets() {
    return this._featureSets;
  }

  get description() {
    return this._description;
  }

  get lastSaleDate() {
    return this._lastSaleDate;
  }

  get tags() {
    return this._tags;
  }

  get label() {
    return this._label;
  }

  get prebook() {
    return this._prebook;
  }

  get status() {
    return this._status;
  }

  get allocation() {
    return this._allocation;
  }

  get thumb() {
    return this._thumb;
  }

  get intervals() {
    return this._intervals;
  }

  get sets() {
    return this._sets;
  }

  get startingPrice() {
    return Math.min(...this._sets.map((set) => set.price));
  }

  get slug() {
    return this._slug;
  }

  get translations() {
    return this._translations;
  }

  static fromAPI(object) {
    return new SpecialEvent(
      object.id,
      object.name,
      object.is_mandatory,
      object.feature_sets,
      object.description,
      object.last_sale_date,
      object.tags,
      object.label,
      object.prebook,
      object.status,
      object.allocation,
      object.thumb,
      object.intervals,
      new ResourceCollection(SpecialEventSet).make(object),
      object.slug,
      object.translations,
    );
  }
}
