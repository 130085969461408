import Resource from './Resource';

export default class Slot extends Resource {
  static collectionKey = null;

  constructor(
    dt,
    disabled,
    discount,
    event,
    reason,
    specialOffer,
  ) {
    super(null);
    this._dt = dt;
    this._disabled = disabled;
    this._discount = discount;
    this._event = event;
    this._reason = reason;
    this._specialOffer = specialOffer;
  }

  get dt() {
    return this._dt;
  }

  get disabled() {
    return this._disabled;
  }

  get discount() {
    return this._discount;
  }

  get event() {
    return this._event;
  }

  get reason() {
    return this._reason;
  }

  get specialOffer() {
    return this._specialOffer;
  }

  static fromAPI(object) {
    return new Slot(
      object.dt,
      object.disabled,
      object.discount,
      object.event,
      object.reason,
      object.special_offer,
    );
  }
}
