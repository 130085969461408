import Resource from './Resource';

export default class Country extends Resource {
  static collectionKey = 'countries';

  constructor(id, name, currency, code, contactPhone, contactEmail, hasPickup) {
    super(id);
    this._name = name;
    this._currency = currency;
    this._code = code;
    this._contactPhone = contactPhone;
    this._contactEmail = contactEmail;
    this._hasPickup = hasPickup;
  }

  get name() {
    return this._name;
  }

  get currency() {
    return this._currency;
  }

  get code() {
    return this._code;
  }

  get contactPhone() {
    return this._contactPhone;
  }

  get contactEmail() {
    return this._contactEmail;
  }

  get hasPickup() {
    return this._hasPickup;
  }

  static fromAPI(object) {
    return new Country(
      object.id,
      object.name,
      object.currency,
      object.code,
      object.contact_phone,
      object.contact_email,
      object.has_pickup,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
      currency: object.currency,
      code: object.code,
      // contactPhone: object.contact_phone,
      // contactEmail: object.contact_email,
      hasPickup: object.has_pickup,
    };
  }
}
