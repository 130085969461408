import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10">
    <g>
      <g><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="20" strokeWidth="2" d="M8.5 8.5l-7-7" /></g>
      <g><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="20" strokeWidth="2" d="M8.5 8.5l7-7" /></g>
    </g>
  </svg>
);

ArrowDown.propTypes = {
  className: PropTypes.string,
};

ArrowDown.defaultProps = {
  className: '',
};

export default ArrowDown;
