import Resource from './Resource';

export default class Neighbourhood extends Resource {
  static collectionKey = 'neighbourhoods';

  constructor(id, name) {
    super(id);
    this._name = name;
  }

  get name() {
    return this._name;
  }

  static fromAPI(object) {
    return new Neighbourhood(
      object.id,
      object.name,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
    };
  }
}
