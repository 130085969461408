@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.no-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  font-size: rem(18px);
  font-weight: bold;
  background-color: $gray;
  border-radius: 16px;

  svg {
    margin-right: 15px;
  }

}

.menu-gallery-wrapper {
  max-width: 580px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.menu-gallery-item {
  @include reset-btn;

  display: block;
  position: relative;
  margin: 0 auto;

  img {
    max-width: 100%;
  }

}

.menu-gallery-controls {
  text-align: center;
  color: $lighter-text-color;

  strong {
    color: $primary-color;
  }

  button {
    @include reset-btn;

    position: absolute;
    top: 50%;
    border: 2px solid $accent-color;
    width: 24px;
    height: 24px;
    line-height: 1;
    margin-top: -12px;
  }

  .btn-prev {
    transform: rotate(90deg);
    left: 0;
  }

  .btn-next {
    transform: rotate(-90deg);
    right: 0;
  }

}
