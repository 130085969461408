import React from 'react';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import './assets/styles/style.scss';

import AlertTemplate from './components/AlertTemplate';
import './i18n';
import Country from './networking/resources/Country';

import CountryContext from './react-web-ui/contexts/CountryContext';
import App from './root/App';
import Landing from './root/Landing';

const countries = (domain) => {
  switch (domain) {
    case 'dineout.bg':
    case 'reservation-form.dineout.dev':
    case 'book.dineout.dev':
    case 'book.dineout.bg':
    case 'website.dineout.com':
      return Country.fromAPI({
        id: '3691308f2a4c2f6983f2880d32e29c84',
        name: 'Bulgaria',
        currency: 'BGN',
        code: 'BG',
      });
    case 'dineout.pl':
    case 'book.dineout.pl':
    case 'raffles.dineout.pl':
    case 'book.staging.dineout.pl':
      return Country.fromAPI({
        id: '7e83de9a1f3a4d52effc3352b336f64f',
        name: 'Poland',
        currency: 'PLN',
        code: 'PL',
      });
    case 'reservation-form.staging.dineout.es':
    case 'dineout.es':
    case 'book.dineout.es':
      return Country.fromAPI({
        id: '8a15024c391d8a93371129285343f5b3',
        name: 'Spain',
        currency: 'EUR',
        code: 'es',
      });
    case 'book.staging.dineout.hr':
    case 'book.dineout.hr':
      return Country.fromAPI({
        id: '92ebe92b748d0fb77c7d5213f998cb9a',
        name: 'Croatia',
        currency: '€',
        code: 'HR',
      });
    case 'book.staging.dineout.it':
    case 'book.dineout.it':
      return Country.fromAPI({
        id: '5d8e997208bdfb60051ab2aab070192b',
        name: 'Italy',
        currency: '€',
        code: 'IT',
      });
    default:
      return Country.fromAPI({
        id: '3691308f2a4c2f6983f2880d32e29c84',
        name: 'Bulgaria',
        currency: 'BGN',
        code: 'BG',
      });
  }
};

const rootElement = document.getElementById('root');
const widgetDivs = document.querySelectorAll('.dineout-widget');

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  transition: transitions.SCALE,
};

const RootApp = () => (
  <React.StrictMode>
    <Router>
      <AlertProvider template={AlertTemplate} {...options}>
        <CountryContext.Provider
          value={{
            country: countries(window.location.hostname),
          }}
        >
          <Landing />
        </CountryContext.Provider>
      </AlertProvider>
    </Router>
  </React.StrictMode>
);

if (widgetDivs.length > 0) {
  widgetDivs.forEach((Div) => {
    ReactDOM.render(
      <React.StrictMode>
        <AlertProvider template={AlertTemplate} {...options}>
          <CountryContext.Provider
            value={{
              country: countries(process.env.REACT_APP_DOMAIN),
            }}
          >
            <App domElement={Div} />
          </CountryContext.Provider>
        </AlertProvider>
      </React.StrictMode>,
      Div,
    );
  });
} else if (rootElement) {
  ReactDOM.render(<RootApp />, rootElement);
}
