import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import TableCombination from './TableCombination';

export default class SalonWithTableCombinations extends Resource {
  static collectionKey = 'salons';

  constructor(
    id,
    combinations,
  ) {
    super(id);
    this._combinations = combinations;
  }

  get combinations() {
    return this._combinations;
  }

  static fromAPI(object) {
    return new SalonWithTableCombinations(
      object.id,
      (new ResourceCollection(TableCombination)).make(object),
    );
  }

  plain() {
    return {
      id: this.id,
      combinations: this.combinations.map((combination) => combination.plain()),
    };
  }
}
