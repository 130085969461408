import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import ReviewReply from './ReviewReply';

export default class Review extends Resource {
  static collectionKey = 'reviews';

  constructor(
    id,
    placeId,
    authorId,
    authorName,
    authorThumb,
    authorReviews,
    content,
    tags,
    tip,
    upvotes,
    status,
    submittedOn,
    replies,
  ) {
    super(id);
    this._placeId = placeId;
    this._authorId = authorId;
    this._authorName = authorName;
    this._authorThumb = authorThumb;
    this._authorReviews = authorReviews;
    this._content = content;
    this._tags = tags;
    this._tip = tip;
    this._upvotes = upvotes;
    this._status = status;
    this._submittedOn = submittedOn;
    this._replies = replies;
  }

  get placeId() {
    return this._placeId;
  }

  get authorId() {
    return this._authorId;
  }

  get authorName() {
    return this._authorName;
  }

  get authorThumb() {
    return this._authorThumb;
  }

  get authorReviews() {
    return this._authorReviews;
  }

  get content() {
    return this._content;
  }

  get tags() {
    return this._tags;
  }

  get tip() {
    return this._tip;
  }

  get upvotes() {
    return this._upvotes;
  }

  get status() {
    return this._status;
  }

  get submittedOn() {
    return new Date(this._submittedOn * 1000);
  }

  get replies() {
    return this._replies;
  }

  static fromAPI(object) {
    return new Review(
      object.id,
      object.place_id,
      object.author_id,
      object.author_name,
      object.author_thumb,
      object.author_reviews,
      object.content,
      object.tags,
      object.tip,
      object.upvotes,
      object.status,
      object.submitted_on,
      (new ResourceCollection(ReviewReply)).make(object),
    );
  }
}
