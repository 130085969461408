{
  "aditional_requests": "Dodatkowe prośby",
  "inside": "W środku",
  "outside": "Na zewnątrz",
  "personal_information": "Dane osobowe",
  "no_time_slots": "Brak wolnych stolików o tej godzinie, sprawdź późniejsze terminy",
  "seating_tooltip": "Wybierz miejsce do siedzenia",
  "seating_tooltip_inside": "Restauracja oferuje wyłącznie",
  "select_date_and_guests": "Wybierz datę i liczbę gości, aby wyświetlić przedziały czasowe",
  "select_time": "Wybierz godzinę",
  "pay": "Zapłać",
  "per_person": "W przypadku tej rezerwacji restauracja wymaga gwarancji karty kredytowej. Zablokujemy Twoją kartę kredytową w kwocie {{amount}} na osobę.",
  "per_reservation": "W przypadku tej rezerwacji restauracja wymaga gwarancji karty kredytowej. Zablokujemy Twoją kartę kredytową w wysokości {{amount}} za rezerwację.",
  "min_people": "Jeśli Twoja rezerwacja obejmuje więcej niż {{min}} osób.",
  "late_cancellation_threshold_per_person": "Jeśli anulujesz rezerwację po {{threshold}}, restauracja pobierze opłatę za późniejsze anulowanie: {{amount}} za osobę.",
  "late_cancellation_threshold_per_reservation": "Jeśli anulujesz rezerwację po {{threshold}}, restauracja pobierze opłatę za późniejsze anulowanie: {{amount}} za rezerwację.",
  "more_info": "Więcej informacji",
  "transactions_text": "Należy pamiętać, że rezerwacja nie jest zakończona do momentu dokonania płatności.",
  "please_note": "Proszę to zanotować:",
  "cancel_for_free": "Możesz anulować tę rezerwację bezpłatnie do {{threshold}}",
  "no_show_charge":"W przypadku niestawienia się na rezerwację restauracja pobierze opłatę w wysokości: {{amount}}",
  "funds_release": "Twoje środki zostaną zwolnione po pomyślnej rezerwacji lub, w przypadku anulowania rezerwacji, w momencie anulowania rezerwacji. Pojawienie się transakcji odwrotnej na karcie kredytowej może zająć do 7 dni."
}
