import BookingManager from './BookingManager';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';

export default class BookingsCountry extends Resource {
  static collectionKey = 'countryBookings';

  constructor(id, bookings, links, meta) {
    super(id);
    this._bookings = bookings;
    this._links = links;
    this._meta = meta;
  }

  get bookings() {
    return this._bookings;
  }

  get links() {
    return this._links;
  }

  get meta() {
    return this._meta;
  }

  static fromAPI(object) {
    return new BookingsCountry(
      object.id,
      object.bookings ? new ResourceCollection(BookingManager).make(object) : [],
      object.links,
      object.meta,
    );
  }
}
