import Resource from './Resource';

export default class DiscountsPlace extends Resource {
  static collectionKey = 'discounts';

  constructor(id, date, start, end, percent, description) {
    super(id);
    this._date = date;
    this._start = start;
    this._end = end;
    this._percent = percent;
    this._description = description;
  }

  get date() {
    return this._date;
  }

  get start() {
    return this._start;
  }

  get end() {
    return this._end;
  }

  get percent() {
    return this._percent;
  }

  get description() {
    return this._description;
  }

  static fromAPI(object) {
    return new DiscountsPlace(
      object.id,
      object.date,
      object.start,
      object.end,
      object.percent,
      object.description,
    );
  }
}
