import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './style.module.scss';

/**
 * Submit button that can be used in forms with different styling.
 * @param text
 * @param classes
 * @param onClick
 * @param disabled
 * @returns {*}
 * @constructor
 */
export default function Button({
  text,
  classes,
  onClick,
  disabled,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="submit"
      className={`${styles.btn} ${classes}`}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
};

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  classes: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
