@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.counter {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;

  button {
    width: 40px;
    height: 40px;
    background-color: $gray;
    border: 0;
    cursor: pointer;
    outline: none;
    font-size: rem(17px);
    font-weight: 700;
  }

  button[disabled] {
    cursor: default;
  }

  p {
    margin: 0;
  }

}

.count {
  width: 30px;
}
