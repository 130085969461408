import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import * as styles from './style.module.scss';

/**
 * Tooltip
 * @param title
 * @param position
 * @param children
 * @param className
 * @returns {*}
 * @constructor
 */
export default function Tooltip({
  title,
  position,
  children,
  className,
}) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  return (
    <div
      className={`${styles['tooltip-wrapper']} ${className}`}
      ref={ref}
      role="button"
      tabIndex="0"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      {show && (
        <div ref={ref} className={`${styles['tooltip-content']} ${position}`}>
          <p>{title}</p>
        </div>
      )}
    </div>
  );
}

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'right',
  className: '',
};
