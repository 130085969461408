@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.powered-by {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -20px -20px 10px;
  padding: 10px 20px;
  background-color: $accent-color;
  color: $white;
  font-size: rem(12px);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.booking-info-block {
  padding: 20px;
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: 16px;
  margin-bottom: 40px;
}

.bookings-info-section {
  margin-top: 30px;
}

.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.guests-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .icon {
    width: auto;
    height: 40px;
    fill: $accent-color;
  }

}

.toggle-seating {
  @include reset-btn;

  position: relative;
  display: flex;
  width: 200px;
  min-height: 32px;
  background-color: $gray;
  font-weight: 500;
  color: $lighter-text-color;
  border-radius: 20px;

  span {
    position: relative;
    z-index: 2;
    width: 50%;
    padding: 8px 5px;
    transition: color 0.4s;
    line-height: 1;

    &:first-child {
      color: $white;
    }

  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
    background-color: $accent-color;
    border-radius: 20px;
    transition: left 0.4s, right 0.4s;
  }

  &[disabled] {
    cursor: not-allowed;

    &::before {
      background-color: $dark-gray;
    }

  }

  &.active {

    span {

      &:first-child {
        color: inherit;
      }

      &:last-child {
        color: $white;
      }

    }

    &::before {
      right: 0;
      left: 50%;
    }

  }

}

.please-select {
  padding: 20px;
  background-color: $gray;
  font-size: rem(20px);
}

.hours-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 5px;
  margin-bottom: 20px;

  button {
    position: relative;
    width: 100%;
    height: 54px;
    padding: 10px 0;
    font-size: rem(16px);
    cursor: pointer;
    outline: none;
    border: 2px solid transparent;
    background-color: $gray;
    font-weight: 500;
  }

  button[disabled] {
    background-color: $gray;
    color: $lighter-text-color;
    cursor: default;
  }

  .hour {
    position: absolute;
    left: 4px;
    bottom: 4px;
  }

  .selected-hour {
    border-color: $accent-color;
    font-weight: 700;
  }

}


.pick-hour.loading {
  position: relative;
  min-height: 100px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(../../assets/images/loader-grey.gif) no-repeat center $gray;
    background-size: auto 86px;
  }

}

.two-columns-form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  > div {
    width: calc(50% - 10px);
  }

}

@include medium {

  .booking-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .date-picker,
  .guests-wrapper {
    margin-bottom: 0;
  }

}
