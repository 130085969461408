import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './style.module.scss';
import CloseIcon from '../SVGComponents/Close';

export default function AlertTemplate({ options, message, close }) {
  return (
    <div
      className={`${styles['custom-alert']}
        ${options.type === 'success' ? styles['success'] : ''}
        ${options.type === 'error' ? styles['error'] : ''}
      `}
    >
      {message}
      <button type="button" onClick={close}>
        <CloseIcon className={styles['icon']} />
      </button>
    </div>
  );
}

AlertTemplate.propTypes = {
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};
