@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import '../../react-web-ui/assets/styles/root-colors';
@import '../../react-web-ui/assets/styles/mixins';

@import '../../react-web-ui/assets/styles/variables';
@import '../../react-web-ui/assets/styles/themes';
@import '../../react-web-ui/components/FormElement/style';
@import '../../react-web-ui/components/FormElement/Checkbox/style';
@import '../../react-web-ui/components/Header/style.scss';
@import '../../react-web-ui/components/PageHeading/style.scss';
/**
* App-wide styles
*/
@import './global';
@import './restaurant-profile';
@import './react-autosuggest';
@import '../../components/CustomPhoneInput/style.scss';

:global {
  @import './global';
  @import '../../components/CustomPhoneInput/style.scss';
}