import React from 'react';
import PropTypes from 'prop-types';

/**
 * Info item
 * @param title
 * @param subtitle
 * @returns {*}
 * @constructor
 */
export default function InfoItem({ icon, title, text }) {
  return (
    <div className="info-item">
      {icon}
      <div className="text">
        <h3>
          {title}
        </h3>
        {typeof text === 'string' ? <p>{text}</p> : text}
      </div>
    </div>
  );
}

InfoItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element.isRequired,
};
