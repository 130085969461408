import MenuItem from './MenuItem';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';

export default class MenuCategory extends Resource {
  static collectionKey = 'menu';

  constructor(id, name, items) {
    super(id);
    this._name = name;
    this._items = items;
  }

  get name() {
    return this._name;
  }

  get items() {
    return this._items;
  }

  static fromAPI(object) {
    return new MenuCategory(
      object.id,
      object.name,
      new ResourceCollection(MenuItem).make(object),
    );
  }
}
