@import '../../../react-web-ui/assets/styles/mixins';
@import '../../../react-web-ui/assets/styles/variables';

.menu-item {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  p {
    margin: 0;
  }

  small {
    display: block;
    font-weight: normal;
    font-style: italic;
  }

  .text {
    width: calc(100% - 25px - 40px);
    word-break: break-word;
  }

  .price {
    margin-left: 5px;
  }

  &:first-child {
    margin-top: 0;
  }

}

.allergens {
  font-size: rem(10px);
  color: $white;
  font-weight: bold;
  margin-left: 6px;
  vertical-align: text-top;
  line-height: 1.4;
  font-style: normal;

  > div {
    display: inline-block;
    margin-right: 4px;
  }

  span {
    display: block;
    width: 16px;
    height: 16px;
    padding-top: 1px;
    border-radius: 50%;
    background-color: $dark-gray;
    text-align: center;
  }

}
