import React from 'react';
import PropTypes from 'prop-types';

const Magnifier = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
    className={className}
  >

    <g id="Layer_x0020_1">
      <path
        className="st0"
        d="M22,10.5c3.2,0,6.4,1.2,8.3,3.6c2,2,3.2,4.8,3.2,7.9c0,2.8-0.8,5.2-2,6.8l7.2,7.5l0,0l0,0
      c0.4,0.4,0.8,0.8,0.8,1.2c0,1.2-0.8,2-2,2c-0.4,0-0.8-0.4-1.2-0.8l0,0l0,0l-7.2-7.2c-2,1.2-4.4,2-7.2,2c-3.2,0-6-1.2-7.9-3.2
      c-2.4-2-3.6-5.2-3.6-8.3s1.2-6,3.6-7.9C16.1,11.7,18.8,10.5,22,10.5z M28,16.5c-1.6-1.6-3.6-2.4-6-2.4c-2,0-4.4,0.8-5.6,2.4
      c-1.6,1.2-2.4,3.6-2.4,5.6c0,2.4,0.8,4.4,2.4,6c1.2,1.2,3.6,2.4,5.6,2.4c2.4,0,4.4-1.2,6-2.4c1.2-1.6,2.4-3.6,2.4-6
      C30.4,20,29.2,17.7,28,16.5z"
      />
    </g>
  </svg>
);

Magnifier.propTypes = {
  className: PropTypes.string,
};

Magnifier.defaultProps = {
  className: '',
};

export default Magnifier;
