import PlaceManager from './PlaceManager';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';

export default class PlacesPagination extends Resource {
  static collectionKey = 'places';

  constructor(id, places, links, meta) {
    super(id);
    this._places = places;
    this._links = links;
    this._meta = meta;
  }

  get places() {
    return this._places;
  }

  get links() {
    return this._links;
  }

  get meta() {
    return this._meta;
  }

  static fromAPI(object) {
    return new PlacesPagination(
      object.id,
      object.places ? new ResourceCollection(PlaceManager).make(object) : [],
      object.links,
      object.meta,
    );
  }
}
