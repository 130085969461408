import Resource from './Resource';

export default class Guest extends Resource {
  static collectionKey = 'guests';

  constructor(
    id,
    firstBookingAt,
    lastBookingAt,
    noShowsCount,
    cancelledCount,
    completedCount,
    names,
    email,
    phone,
    reference,
    birthday,
    notes,
  ) {
    super(id);
    this._firstBookingAt = firstBookingAt;
    this._lastBookingAt = lastBookingAt;
    this._noShowsCount = noShowsCount;
    this._cancelledCount = cancelledCount;
    this._completedCount = completedCount;
    this._names = names;
    this._email = email;
    this._phone = phone;
    this._reference = reference;
    this._birthday = birthday;
    this._notes = notes;
  }

  get firstBookingAt() {
    return this._firstBookingAt;
  }

  get lastBookingAt() {
    return this._lastBookingAt;
  }

  get noShowsCount() {
    return this._noShowsCount;
  }

  get cancelledCount() {
    return this._cancelledCount;
  }

  get completedCount() {
    return this._completedCount;
  }

  get names() {
    return this._names;
  }

  get email() {
    return this._email;
  }

  get phone() {
    return this._phone;
  }

  get reference() {
    return this._reference;
  }

  get birthday() {
    return this._birthday;
  }

  get notes() {
    return this._notes;
  }

  static fromAPI(object) {
    return new Guest(
      object.id,
      object.first_booking_at,
      object.last_booking_at,
      object.no_shows_count,
      object.cancelled_count,
      object.completed_count,
      object.names,
      object.email,
      object.phone,
      object.reference,
      object.birthday,
      object.notes,
    );
  }
}
