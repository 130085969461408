import React from 'react';
import PropTypes from 'prop-types';

export default function PageHeading({ title, classes, content }) {
  return (
    <div className={`page-heading ${classes}`}>
      <h1>{title}</h1>
      {content}
    </div>
  );
}

PageHeading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  classes: PropTypes.string,
  content: PropTypes.element,
};

PageHeading.defaultProps = {
  classes: '',
  content: null,
};
