import City from './City';
import Cuisine from './Cuisine';
import DiscountsPlace from './DiscountsPlace';
import Neighbourhood from './Neighbourhood';
import Place from './Place';
import ResourceCollection from './ResourceCollection';

export default class PlaceWithDiscounts extends Place {
  static collectionKey = 'discounts';

  constructor(
    id,
    name,
    subtitle,
    city,
    neighbourhood,
    address,
    description,
    slug,
    cuisines,
    priceRange,
    mealDuration,
    dinnerDuration,
    lat,
    lng,
    rateFood,
    rateService,
    rateAmbience,
    rateCount,
    freeTables,
    isFav,
    images,
    thumb,
    distance,
    prebookDuration,
    chefName,
    awardsList,
    isPermanentlyClosed,
    isRecommendedForBusinessMeetings,
    isRecommendedForRomanticDate,
    isRecommendedForWithKids,
    isRecommendedForBigGroups,
    acceptsOnlineBookings,
    contactPhoneNumber,
    kidsPlace,
    cardPay,
    wifi,
    parking,
    petFriendly,
    discounts,
  ) {
    super(
      id,
      name,
      subtitle,
      city,
      neighbourhood,
      address,
      description,
      slug,
      cuisines,
      priceRange,
      mealDuration,
      dinnerDuration,
      lat,
      lng,
      rateFood,
      rateService,
      rateAmbience,
      rateCount,
      freeTables,
      isFav,
      images,
      thumb,
      distance,
      prebookDuration,
      chefName,
      awardsList,
      isPermanentlyClosed,
      isRecommendedForBusinessMeetings,
      isRecommendedForRomanticDate,
      isRecommendedForWithKids,
      isRecommendedForBigGroups,
      acceptsOnlineBookings,
      contactPhoneNumber,
      kidsPlace,
      cardPay,
      wifi,
      parking,
      petFriendly,
    );
    this._discounts = discounts;
  }

  get discounts() {
    return this._discounts;
  }

  get maxDiscount() {
    return Math.max(...this._discounts.map((discount) => discount.percent));
  }

  static fromAPI(object) {
    return new PlaceWithDiscounts(
      object.id,
      object.name,
      object.subtitle,
      City.fromAPI(object.city),
      Neighbourhood.fromAPI(object.neighbourhood),
      object.address,
      object.description,
      object.slug,
      new ResourceCollection(Cuisine).make(object),
      object.price_range,
      object.meal_duration,
      object.dinner_duration,
      object.lat,
      object.lng,
      object.rate_food,
      object.rate_service,
      object.rate_ambience,
      object.rate_count,
      object.free_tables,
      object.is_fav,
      object.images,
      object.thumb,
      object.distance,
      object.prebook_duration,
      object.chef_name,
      object.awards_list,
      object.is_permanently_closed,
      object.is_recommended_for_business_meetings,
      object.is_recommended_for_romantic_date,
      object.is_recommended_for_with_kids,
      object.is_recommended_for_big_groups,
      object.accepts_online_bookings,
      object.contact_phone_number,
      object.kids_place,
      object.card_pay,
      object.wifi,
      object.parking,
      object.pet_friendly,
      new ResourceCollection(DiscountsPlace).make(object),
    );
  }
}
