@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.custom-alert {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 5px;
  color: $white;
  background-color: $accent-color;
  pointer-events: all;

  button {
    @include reset-btn;
    
    margin-left: 5px;
    cursor: pointer;
  }

  .icon {
    width: 40px;
    height: 40px;
    fill: $white;
  }

  &.success {
    background-color: $success-color;
  }

  &.error {
    background-color: $error-color;
  }

}
