import Resource from './Resource';

export default class Transaction extends Resource {
  static collectionKey = 'transactions';

  constructor(id, amount, currency, status, type, refundedOn, capturedOn, completedOn) {
    super(id);
    this._amount = amount;
    this._currency = currency;
    this._status = status;
    this._type = type;
    this._refundedOn = refundedOn;
    this._capturedOn = capturedOn;
    this._completedOn = completedOn;
    this._id = id;
  }

  get amount() {
    return this._amount;
  }

  get id() {
    return this._id;
  }

  get currency() {
    return this._currency;
  }

  get status() {
    return this._status;
  }

  get type() {
    return this._type;
  }

  get refundedOn() {
    return this._refundedOn;
  }

  get capturedOn() {
    return this._capturedOn;
  }

  get completedOn() {
    return this._completedOn;
  }

  static fromAPI(object) {
    return new Transaction(
      object.id,
      object.amount,
      object.currency,
      object.status,
      object.type,
      object.refunded_on,
      object.captured_on,
      object.completed_on,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      amount: object.amount,
      currency: object.currency,
      status: object.status,
      type: object.type,
      refundedOn: object.refundedOn,
      capturedOn: object.capturedOn,
      completedOn: object.completedOn,
    };
  }
}
