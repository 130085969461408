import Resource from './Resource';

export default class Gift extends Resource {
  static collectionKey = 'gifts';

  constructor(
    id,
    code,
    wish,
    value,
    status,
    currency,
    expireAt,
  ) {
    super(id);
    this._code = code;
    this._wish = wish;
    this._value = value;
    this._status = status;
    this._currency = currency;
    this._expireAt = expireAt;
    this._id = id;
  }

  get id() {
    return this._id;
  }

  get code() {
    return this._code;
  }

  get wish() {
    return this._wish;
  }

  get value() {
    return this._value;
  }

  get status() {
    return this._status;
  }

  get currency() {
    return this._currency;
  }

  get expireAt() {
    return this._expireAt;
  }

  static fromAPI(object) {
    return new Gift(
      object.id,
      object.code,
      object.wish,
      object.value,
      object.status,
      object.currency,
      object.expire_at,
    );
  }
}
