import Elements from './Elements';
import Resource from './Resource';
import ResourceCollection from './ResourceCollection';

export default class SalonWithElements extends Resource {
  static collectionKey = 'salons';

  constructor(
    id,
    name,
    outside,
    active,
    exists,
    elements,
  ) {
    super(id);
    this._name = name;
    this._outside = outside;
    this._active = active;
    this._exists = exists;
    this._elements = elements;
  }

  get name() {
    return this._name;
  }

  get outside() {
    return this._outside;
  }

  get active() {
    return this._active;
  }

  get exists() {
    return this._exists;
  }

  get elements() {
    return this._elements;
  }

  static fromAPI(object) {
    return new SalonWithElements(
      object.id,
      object.name,
      object.outside,
      object.active,
      true, // as it comes from api ;p
      object.elements ? (new ResourceCollection(Elements)).make(object) : [],
    );
  }

  plain() {
    return {
      id: this.id,
      name: this.name,
      outside: this.outside,
      active: this.active,
      exists: this.exists,
      elements: this.elements ? this.elements.map((element) => element.plain()) : [],
    };
  }
}
