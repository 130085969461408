{
  "booking_error": "Неуспешна резервация, проверете въведените данни.",
  "booking_success": "Успешна резервация",
  "check_terms_error": "Моля, прочетете и се съгласете с общите условия",
  "email": "Имейл",
  "email_error_empty": "Моля, въведете имейл адрес",
  "email_error_invalid": "Моля, въведете валиден имейл",
  "first_name": "Име",
  "first_name_error_empty": "Моля, въведете име",
  "last_name": "Фамилия",
  "last_name_error_empty": "Моля, въведете фамилия",
  "phone": "Телефон",
  "phone_error_empty": "Моля, въведете телефонен номер",
  "phone_error_invalid": "Моля, въведете валиден телефонен номер",
  "read_and_accept": "Запознах се и се съгласявам с ",
  "terms_and_conditions": "общите условия на Dineout.",
  "select_date": "Изберете дата",
  "success_send": "Успешно изпратено запитване!",
  "notes": "Допълнителни изисквания към резервацията"
}
