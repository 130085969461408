import Resource from './Resource';
import ResourceCollection from './ResourceCollection';
import TableWithoutSalon from './TableWithoutSalon';

export default class Salon extends Resource {
  static collectionKey = 'salons';

  constructor(
    id,
    name,
    outside,
    active,
    exists,
    tables,
  ) {
    super(id);
    this._name = name;
    this._outside = outside;
    this._active = active;
    this._exists = exists;
    this._tables = tables;
  }

  get name() {
    return this._name;
  }

  get outside() {
    return this._outside;
  }

  get active() {
    return this._active;
  }

  get exists() {
    return this._exists;
  }

  get tables() {
    return this._tables;
  }

  static fromAPI(object) {
    return new Salon(
      object.id,
      object.name,
      object.outside,
      object.active,
      true, // as it comes from api ;p
      object.tables ? (new ResourceCollection(TableWithoutSalon)).make(object) : [],
    );
  }

  plain() {
    return {
      id: this.id,
      name: this.name,
      outside: this.outside,
      active: this.active,
      exists: this.exists,
      tables: this.tables ? this.tables.map((table) => table.plain()) : [],
    };
  }
}
