import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import RequestService from '../../networking/RequestService';
import Services from './Services';

export default function ServicesSection({
  restaurantId,
  name,
  slug,
}) {
  const { t } = useTranslation();
  const [specialOffers, setSpecialOffers] = useState([]);
  const [tasting, setTasting] = useState([]);
  const [event, setEvent] = useState([]);

  useEffect(() => {
    if (restaurantId) {
      (new RequestService('client/special-offers/place'))
        .setParams({
          place_id: restaurantId,
        })
        .send()
        .then((response) => {
          if (response.data.length > 0) setSpecialOffers(response.data);
        })
        .catch(() => null);
      (new RequestService('client/special-events/place'))
        .setParams({
          place_id: restaurantId,
          with_tasting_menus: true,
        })
        .send()
        .then((response) => {
          setTasting(response.data.filter((item) => item.featureSets));
          setEvent(response.data.filter((item) => !item.featureSets));
        })
        .catch(() => null);
    }
  }, [restaurantId]);

  return (
    <section className="section">
      <h2 className="section-title">
        <strong>{name}</strong>
        {` ${t('pageRestaurantProfile:section_offers_title')} `}
      </h2>
      {(specialOffers.length > 0
        || tasting.length > 0
        || event.length > 0) && (
        <Services
          specialOffers={specialOffers}
          tasting={tasting}
          event={event}
          slug={slug}
        />
      )}
    </section>
  );
}

ServicesSection.propTypes = {
  restaurantId: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
};

ServicesSection.defaultProps = {
  restaurantId: '',
  name: '',
  slug: '',
};
