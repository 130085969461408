{
  "booking_error": "Prenotazione non riuscita, per favore controlla i dati inseriti",
  "booking_success": "Prenotazione riuscita",
  "check_terms_error": "Per favore leggi e accetta Termini e Condizioni ",
  "email": "Email",
  "email_error_empty": "Inserici un indirizzo Email",
  "email_error_invalid": "Inserisci un indirizzo Email valido",
  "first_name": "Nome",
  "first_name_error_empty": "Per favore inserisci il tuo nome",
  "last_name": "Cognome",
  "last_name_error_empty": "Per favore inserisci il tuo cognome",
  "phone": "Telefono",
  "phone_error_empty": "Inserisci il numero di telefono",
  "phone_error_invalid": "Inserisci un numero di telefono valido",
  "read_and_accept": "Ho letto e accetto il ",
  "terms_and_conditions": "Termini & Condizioni",
  "select_date": "Scegli una data",
  "success_send": "Il tuo messaggio è stato inviato con successo.",
  "notes": "Richieste aggiuntive riguardo la tua prenotazione"
}
