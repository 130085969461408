import Resource from './Resource';

export default class Resolution extends Resource {
  static collectionKey = 'resolutions';

  constructor(
    id,
    label,
    description,
  ) {
    super(id);
    this._label = label;
    this._description = description;
    this._id = id;
  }

  get id() {
    return this._id;
  }

  get label() {
    return this._label;
  }

  get description() {
    return this._description;
  }

  static fromAPI(object) {
    return new Resolution(
      object.key,
      object.label,
      object.description,
    );
  }
}
