{
  "settings": "Settings:",
  "preview": "Preview:",
  "include_services": "Include Services",
  "include_reviews": "Include Rating & Reviews",
  "include_menu": "Include À la carte menu",
  "how_to_install": "How to install:",
  "include_css": "1. Include Widget styles on your page in the <head> of the html:",
  "include_widget": "2. Add this code where you want the Widget to be displayed on your page:",
  "include_script": "3. Include the main Widget script on your page after the <div> from step 2, ideally immediately before the closing </body> tag:",
  "copy": "copy",
  "copied": "Copied",
  "no_suggestions": "No suggestions",
  "find_restaurant": "Find a restaurant’s booking form",
  "find": "Find",
  "type_restaurant_name": "Type a restaurant’s name",
  "powered_by": "Powered by",
  "email_ivanka": "4. Email i.todorova@dineout.pl with the domain you'd like to put your widget on.",
  "change_accent_color": "Change highlight color"
}
