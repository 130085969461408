@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.restaurant-header {
  position: static;
  height: auto;
  margin-bottom: 40px;
  padding-top: 9px;
  padding-bottom: 10px;
}

.restaurant-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    display: none !important;
  }

}

.widget-info-block {
  margin-top: 40px;
  margin-bottom: -30px;
  padding-top: 30px;
  text-align: center;
  background-color: $gray;

  img {
    max-width: 100%;
    margin-top: -40px;
  }

  &.with-img {
    margin-top: 60px;
    padding-top: 0;
  }

}

.logo {
  display: block;
  width: 104px;
  height: auto;
}

.restaurant-name {
  font-size: rem(24px);
  font-weight: normal;
}


.copy-input {
  position: relative;
  margin-bottom: 40px;

  button {
    @include reset-btn;

    position: absolute;
    z-index: 2;
    right: 10px;
    top: 14px;
    color: $accent-color;
    font-weight: 700;
  }

  input,
  textarea {
    padding-right: 95px;
  }

  svg {
    top: 12px;
    left: 6px;
    width: 20px;
    color: $accent-color;
  }

}

.widget-color {
  display: flex;
  align-items: center;

  h3 {
    margin-right: 30px;
  }

}

.label {
  margin-right: 10px;
  font-size: rem(14px);
}

.color-form-group {
  display: flex;
  align-items: center;

  input {
    border: none;
    background-color: transparent;
    height: 30px;
    width: 60px;
    margin-left: -4px;
  }
  
}

@include large {

  .widget-info-block {

    img {
      margin-top: -100px;
    }

    &.with-img {
      margin-top: 160px;
    }

  }

}