export default class ResourceCollectionPlain extends Array {
  /**
   * Resource type of elements in the collection.
   */
  resourceType;

  constructor(resourceType) {
    super();
    this.resourceType = resourceType;
  }

  make(items) {
    let iterables = items;
    if (this.resourceType.collectionKey !== null) {
      iterables = items[this.resourceType.collectionKey];
    }

    iterables.map(
      (item) => this.push(this.resourceType.plain(item)),
    );
    return this;
  }
}
