import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as styles from './style.module.scss';

export default function LanguageSwitcher({ locales }) {
  const { i18n } = useTranslation();
  return (
    <ul className={styles['language-switcher']}>
      {locales.map((locale) => (
        <li key={locale}>
          <a
            onClick={(event) => {
              event.preventDefault();
              i18n.changeLanguage(locale);
            }}
            href={`/${locale}`}
            className={`
              ${i18n.language === locale ? styles['active'] : ' '}
            `}
          >
            {locale}
          </a>
        </li>
      ))}
    </ul>
  );
}

LanguageSwitcher.propTypes = {
  /** Locales available for changing. */
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
};
