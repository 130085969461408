import Resource from './Resource';

export default class Employee extends Resource {
  static collectionKey = 'hosts';

  constructor(
    id,
    firstName,
    lastName,
    email,
    privileges,
    isActive,
  ) {
    super(id);
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._privileges = privileges;
    this._isActive = isActive;
  }

  get id() {
    return this._id;
  }

  get firstName() {
    return this._firstName;
  }

  get lastName() {
    return this._lastName;
  }

  get email() {
    return this._email;
  }

  get privileges() {
    return this._privileges;
  }

  get isActive() {
    return this._isActive;
  }

  static fromAPI(object) {
    return new Employee(
      object.id,
      object.first_name,
      object.last_name,
      object.email,
      object.privileges,
      object.is_active,
    );
  }
}
