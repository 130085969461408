{
  "title": "Zasilany przez Dineout - nowoczesny sposób rezerwacji",
  "book_now": "ZAREZERWUJ TERAZ",
  "book_again": "zarezerwuj ponownie",
  "special_offers": "Oferty specjalne",
  "book_event": "Wydarzenia kulinarne",
  "book_tasting": "Menu degustacyjne",
  "mon": "Pon",
  "tue": "Wt",
  "wed": "Śro",
  "thu": "Czw",
  "fri": "Pią",
  "sat": "Sob",
  "sun": "Nie",
  "closed": "Zamknięte",
  "terms_of_use": "Warunki świadczenia usług",
  "visit_dineout": "Odwiedź",
  "GLUTEN_KEY": "Gluten",
  "CRUSTACEANS_KEY": "Skorupiaki",
  "EGGS_KEY": "Jajka",
  "FISH_KEY": "Ryby",
  "PEANUT_KEY": "Orzeszki ziemne",
  "SOYBEANS_KEY": "Soja",
  "MILK_KEY": "Mleko",
  "NUTS_KEY": "Orzechy",
  "CELERY_KEY": "Seler",
  "MUSTARD_KEY": "Musztarda",
  "SESAME_KEY": "Ziarna sezamu",
  "SULPHUR_DIOXIDE_AND_SULPHIDES_KEY": "Dwutlenek siarki i siarczyn",
  "LUPIN_KEY": "Łubin",
  "MOLLUSCS_KEY": "Małże",
  "restaurant_not_found": "Restauracja nie znaleziona",
  "loading": "Ładowanie..."
}
