import Resource from './Resource';

export default class SpecialOffer extends Resource {
  static collectionKey = 'special_offers';

  constructor(
    id,
    specialOfferTypeId,
    placeId,
    specialOfferKey,
    slug,
    defaultName,
    defaultDescription,
    image,
    price,
    discountedPrice,
    status,
    percentage,
    intervals,
    thumb,
    type,
    translations,
    items,
  ) {
    super(id);
    this._specialOfferTypeId = specialOfferTypeId;
    this._placeId = placeId;
    this._specialOfferKey = specialOfferKey;
    this._slug = slug;
    this._defaultName = defaultName;
    this._defaultDescription = defaultDescription;
    this._image = image;
    this._price = price;
    this._discountedPrice = discountedPrice;
    this._status = status;
    this._percentage = percentage;
    this._intervals = intervals;
    this._thumb = thumb;
    this._type = type;
    this._translations = translations;
    this._items = items;
  }

  get specialOfferTypeId() {
    return this._specialOfferTypeId;
  }

  get placeId() {
    return this._placeId;
  }

  get specialOfferKey() {
    return this._specialOfferKey;
  }

  get slug() {
    return this._slug;
  }

  get defaultName() {
    return this._defaultName;
  }

  get defaultDescription() {
    return this._defaultDescription;
  }

  get image() {
    return this._image;
  }

  get price() {
    return this._price;
  }

  get discountedPrice() {
    return this._discountedPrice;
  }

  get status() {
    return this._status;
  }

  get percentage() {
    return this._percentage;
  }

  get intervals() {
    return this._intervals;
  }

  get thumb() {
    return this._thumb;
  }

  get type() {
    return this._type;
  }

  get translations() {
    return this._translations;
  }

  get items() {
    return this._items;
  }

  static fromAPI(object) {
    return new SpecialOffer(
      object.id,
      object.special_offer_type_id,
      object.place_id,
      object.special_offer_key,
      object.slug,
      object.default_name,
      object.default_description,
      object.image,
      object.price,
      object.discounted_price,
      object.status,
      object.percentage,
      object.intervals,
      object.thumb,
      object.type,
      object.translations,
      object.items,
    );
  }
}
