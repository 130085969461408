import Place from './Place';
import SpecialEvent from './SpecialEvent';

export default class SpecialEventWithPlace extends SpecialEvent {
  static collectionKey = 'special_events';

  constructor(
    id,
    name,
    isMandatory,
    featureSets,
    description,
    lastSaleDate,
    tags,
    label,
    prebook,
    status,
    allocation,
    thumb,
    intervals,
    sets,
    place,
    slug,
    translations,
  ) {
    super(
      id,
      name,
      isMandatory,
      featureSets,
      description,
      lastSaleDate,
      tags,
      label,
      prebook,
      status,
      allocation,
      thumb,
      intervals,
      sets,
      slug,
      translations,
    );
    this._place = place;
  }

  get place() {
    return this._place;
  }

  static fromAPI(object) {
    return new SpecialEventWithPlace(
      object.id,
      object.name,
      object.is_mandatory,
      object.feature_sets,
      object.description,
      object.last_sale_date,
      object.tags,
      object.label,
      object.prebook,
      object.status,
      object.allocation,
      object.thumb,
      object.intervals,
      object.sets,
      Place.fromAPI(object.place),
      object.slug,
      object.translations,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
      isMandatory: object.is_mandatory,
      featureSets: object.feature_sets,
      description: object.description,
      lastSaleDate: object.last_sale_date,
      tags: object.tags,
      label: object.label,
      prebook: object.prebook,
      status: object.status,
      allocation: object.allocation,
      thumb: object.thumb,
      intervals: object.intervals,
      sets: object.sets,
      place: Place.plain(object.place),
      slug: object.slug,
      translations: object.translations || null,
    };
  }
}
