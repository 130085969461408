@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.tooltip-wrapper {
  @include clearfix;
  @include reset-btn;
  
  position: relative;
}

.tooltip-content {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 50%;
  margin-top: 12px;
  min-width: 150px;
  transform: translateX(-50%);
  padding: 10px;
  background-color: $gray;
  color: $gray;
  box-shadow: $box-shadow;
  
  p {
    margin: 0;
    color: $primary-color;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    left: 50%;
    bottom: 100%;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent currentColor transparent;
    transform: translateX(-50%);
  }

}
