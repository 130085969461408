import Resource from './Resource';

export default class Marketing extends Resource {
  static collectionKey = 'users';

  constructor(
    id,
    isSubscribed,
    placesManagedCount,
    position,
    source,
  ) {
    super(id);
    this._isSubscribed = isSubscribed;
    this._placesManagedCount = placesManagedCount;
    this._position = position;
    this._source = source;
  }

  get id() {
    return this._id;
  }

  get isSubscribed() {
    return this._isSubscribed;
  }

  get source() {
    return this._source;
  }

  get placesManagedCount() {
    return this._placesManagedCount;
  }

  get position() {
    return this._position;
  }

  static fromAPI(object) {
    return new Marketing(
      object.id,
      object.is_subscribed,
      object.places_managed_count,
      object.position,
      object.source,
    );
  }
}
