{
  "booking_error": "Neuspješna rezervacija, provjerite unesene podatke",
  "booking_success": "Uspješna rezervacija",
  "check_terms_error": "Molimo Vas da pročitate i prihvatite Uvjete i odredbe.",
  "email": "E-mail",
  "email_error_empty": "Unesite e-mail adresu",
  "email_error_invalid": "Unesite valjanua e-mail adresu",
  "first_name": "Ime",
  "first_name_error_empty": "Unesite svoje ime",
  "last_name": "Prezime",
  "last_name_error_empty": "Unesite prezime",
  "phone": "Telefon",
  "phone_error_empty": "Unesite svoj telefonski broj",
  "phone_error_invalid": "Unesite valjani telefonski broj",
  "read_and_accept": "Pročitao/la sam i prihvaćam",
  "terms_and_conditions": "uvjeti i odredbe",
  "select_date": "Odabir datuma",
  "success_send": "Poruka je uspješno poslana.",
  "notes": "Dodatni zahtjevi u vezi s vašom rezervacijom"
}
