import Resource from './Resource';

export default class Cuisine extends Resource {
  static collectionKey = 'cuisines';

  constructor(id, name, count) {
    super(id);
    this._name = name;
    this._count = count;
  }

  get name() {
    return this._name;
  }

  get count() {
    return this._count;
  }

  static fromAPI(object) {
    return new Cuisine(
      object.id,
      object.name,
      object.count,
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
      count: object.count,
    };
  }
}
