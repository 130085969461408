import Country from './Country';
import Resource from './Resource';

export default class City extends Resource {
  static collectionKey = 'cities';

  constructor(id, name, slug, lat, lng, timezone, thumb, count, country) {
    super(id);
    this._name = name;
    this._slug = slug;
    this._lat = lat;
    this._lng = lng;
    this._timezone = timezone;
    this._thumb = thumb;
    this._count = count;
    this._country = country;
  }

  get name() {
    return this._name;
  }

  get slug() {
    return this._slug;
  }

  get lat() {
    return this._lat;
  }

  get lng() {
    return this._lng;
  }

  get timezone() {
    return this._timezone;
  }

  get thumb() {
    return this._thumb;
  }

  get count() {
    return this._count;
  }

  get country() {
    return this._country;
  }

  static fromAPI(object) {
    return new City(
      object.id,
      object.name,
      object.slug,
      object.lat,
      object.lng,
      object.timezone,
      object.thumb,
      object.count,
      Country.fromAPI(object.country),
    );
  }

  static plain(object) {
    return {
      id: object.id,
      name: object.name,
      slug: object.slug,
      lat: object.lat,
      lng: object.lng,
      timezone: object.timezone,
      thumb: object.thumb,
      count: object.count,
      country: Country.plain(object.country),
    };
  }
}
