{
  "settings": "Settaggi",
  "preview": "Anteprima",
  "include_services": "Includi servizi",
  "include_reviews": "Includi valutazioni e recensioni",
  "include_menu": "Aggiungi un menu alla carta",
  "how_to_install": "Come installare il Modulo",
  "include_css": "1. Includi gli stili del modulo in the <head> html:",
  "include_widget": "2. Aggiungi questo codice dove vuoi che il modulo venga visualizzato sulla tua pagina:",
  "include_script": "3. Includi lo script del modulo principale sulla tua pagina immediatamente prima di chiudere il <body> tag:",
  "copy": "copia",
  "copied": "Copiato",
  "no_suggestions": "Nessun suggerimento",
  "find_restaurant": "Cerca un modulo di prenotazione del ristorante",
  "find": "Cerca",
  "type_restaurant_name": "Digitai il nome del ristorante",
  "powered_by": "Offerto da",
  "email_ivanka": "4. Invia un'e-mail a i.todorova@dineout.pl con il dominio su cui desideri inserire il tuo modulo.",
  "change_accent_color": "Cambia il colore di evidenziazione"
}
