@import '../../react-web-ui/assets/styles/mixins';
@import '../../react-web-ui/assets/styles/variables';

.page-footer {
  margin-top: 30px;
  background-color: $white;
  background-color: $accent-color;

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: block;
  }

  a {
    display: block;
    padding: 5px 0;
    text-transform: uppercase;
    color: $white;
  }

}