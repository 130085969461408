import React from 'react';

import * as styles from './style.module.scss';

/**
 * Spinner
 * @returns {*}
 * @constructor
 */
export default function Spinner() {
  return (
    <div className={styles.loader}>Loading</div>
  );
}
