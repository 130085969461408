{
  "section_offers_title": "също предлага",
  "section_rating_title": "Отзиви и оценки за",
  "section_menu_title": "основно меню",
  "food": "Храна",
  "service": "Обслужване",
  "ambience": "Атмосфера",
  "ratings": "оценки",
  "total_rating": "Обща оценка",
  "read_more_reviews": "Още отзиви",
  "still_tasting": "Все още дегустираме това меню.",
  "accepts_bookings": "Приема резервации",
  "chef_name": "Шеф готвач",
  "location": "Адрес",
  "contacts": "Контакти",
  "see_map": "Вижте на картата"
}
