import React from 'react';
import PropTypes from 'prop-types';

const PlusOnly = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15.283" height="15.283" viewBox="0 0 15.283 15.283">
    <g transform="translate(0)">
      <path d="M0,0V15.283" transform="translate(7.642)" fill="currentColor" stroke="currentColor" strokeWidth="2" />
      <path d="M0,0V15.283" transform="translate(15.283 7.642) rotate(90)" fill="currentColor" stroke="currentColor" strokeWidth="2" />
    </g>
  </svg>

);

PlusOnly.propTypes = {
  className: PropTypes.string,
};

PlusOnly.defaultProps = {
  className: '',
};

export default PlusOnly;
