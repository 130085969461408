import Resource from './Resource';

export default class InvoiceDetails extends Resource {
  static collectionKey = 'invoice_details';

  constructor(
    id,
    companyName,
    country,
    city,
    addressLine1,
    addressLine2,
    vatId,
  ) {
    super(id);
    this._companyName = companyName;
    this._country = country;
    this._city = city;
    this._addressLine1 = addressLine1;
    this._addressLine2 = addressLine2;
    this._vatId = vatId;
  }

  get companyName() {
    return this._companyName;
  }

  get country() {
    return this._country;
  }

  get city() {
    return this._city;
  }

  get addressLine1() {
    return this._addressLine1;
  }

  get addressLine2() {
    return this._addressLine2;
  }

  get vatId() {
    return this._vatId;
  }

  static fromAPI(object) {
    return new InvoiceDetails(
      object.id,
      object.company_name,
      object.country,
      object.city,
      object.address_line_1,
      object.address_line_2,
      object.vat_id,
    );
  }
}
